import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/auth'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyAZor4eqxKcvxo4UI7vy9OVBU0-It4juHY',
  authDomain: 'carbee-4edd8.firebaseapp.com',
  databaseURL: 'https://carbee-4edd8.firebaseio.com',
  projectId: 'carbee-4edd8',
  storageBucket: 'carbee-4edd8.appspot.com',
  messagingSenderId: '349671829367',
  appId: '1:349671829367:web:93029fcd931af4e3a07b72',
  measurementId: 'G-CFV5G6Q1G9',
}

const app = firebase.initializeApp(firebaseConfig)

if (process.env.NODE_ENV === 'development') {
  const ip = '192.168.100.83'

  app.firestore().settings({
    host: `${ip}:5000`,
    ssl: false,
  })

  app.functions().useFunctionsEmulator(`http://${ip}:5001`)

  app.auth().useEmulator(`http://${ip}:5005`)
}

const db = app.firestore()
const auth = app.auth()
const storage = app.storage()
const functions = app.functions()

const collections = {
  users: db.collection('users'),
  companiesCodes: db.collection('companiesCodes'),
  orders: db.collection('washingOrders'),
  ratings: db.collection('ratings'),
  conversions: db.collection('conversions'),
  orderAttempts: db.collection('orderAttempts'),
  vouchers: db.collection('vouchers'),
}

export {
  db,
  auth,
  functions,
  storage,
  app,
  firebase,
  collections,
}
