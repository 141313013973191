import {
  firebase, db, functions,
} from '../firebase'

import { IVoucherSet } from './types'

type SnapshotCallback = (
  snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
) => void

const mainRef = db.collection('vouchers')

class Voucher {
  static getAll(callback: SnapshotCallback): any {
    const unsubscribe = mainRef
      .onSnapshot((snapshot) => {
        callback(snapshot)
      })

    return unsubscribe
  }

  static async set(data: IVoucherSet, objective: string): Promise<{ id: string }> {
    const cloudSetVoucher = functions.httpsCallable('setVoucher')
    const response = await cloudSetVoucher({
      objective,
      id: data.id,
      discount: Number(data.discount),
      usageLimit: Number(data.usageLimit),
      expirationDate: data.expirationDate,
      purchaseLimit: Number(data.purchaseLimit),
      bees: data.bees,
      address: data.address,
      chooseRadius: data.chooseRadius,
      radius: data.radius,
    })

    return response.data.id
  }

  static async delete(id: string): Promise<void> {
    const cloudDeleteVoucher = functions.httpsCallable('deleteVoucher')
    await cloudDeleteVoucher({ id })
  }
}

export default Voucher
