/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from 'react'

import {
  Container, FormControl, Grid, InputLabel, MenuItem, Select,
} from '@material-ui/core'

import {
  useLocation, useHistory,

} from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import PartnerRatings from './Partner'
import CustomerRatings from './Customer'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(8, 0, 8),
  },
}))

const Ratings: React.FC = () => {
  const classes = useStyles()
  const [category, setCategory] = useState('partner')
  const history = useHistory()

  const params = new URLSearchParams(useLocation().search)

  const queryCategory = useMemo(() => params.get('category'), [params])

  useEffect(() => {
    if (queryCategory !== 'partner' && queryCategory !== 'customer') {
      return
    }

    setCategory(queryCategory)
  }, [queryCategory])

  const getCategoryScreen = (): JSX.Element | null => {
    switch (category) {
      case 'partner': {
        return <PartnerRatings />
      }
      case 'customer': {
        return <CustomerRatings />
      }
      default: {
        return null
      }
    }
  }

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Grid container justify="center">
        <Grid item xs={12} lg={10}>
          <FormControl variant="outlined" style={{ marginBottom: 20 }}>
            <InputLabel id="demo-simple-select-outlined-label">Categoria</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={category}
              label="Categoria"
              onChange={(value) => {
                history.push(`/ratings?category=${value.target.value as string}`)
                setCategory(value.target.value as string)
              }}
            >
              <MenuItem value="partner">Avaliações criadas pelos Clientes</MenuItem>
              <MenuItem value="customer">Avaliações criadas pelos Parceiros</MenuItem>
            </Select>
          </FormControl>
          {getCategoryScreen()}
        </Grid>
      </Grid>
    </Container>
  )
}

export default Ratings
