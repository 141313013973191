import React, { useEffect } from 'react'

import {
  useHistory,
} from 'react-router-dom'

// Api
import { Auth } from '../../services/api'

// Screens
import Loading from '../Loading'

const SignOut: React.FC = () => {
  const history = useHistory()

  useEffect(() => {
    (async () => {
      await Auth.signOut()
      history.push('/')
    })()
  }, [history])

  return <Loading />
}

export default SignOut
