import {
  auth, firebase,
} from './firebase'

type AuthStateChangeHandler = (user: firebase.User | null) => void

export default class {
  static async signIn(email: string, password: string): Promise<firebase.auth.UserCredential> {
    const userCred = await auth.signInWithEmailAndPassword(email, password)
    return userCred
  }

  static async signOut(): Promise<void> {
    await auth.signOut()
  }

  static subscribeToAuthChanges(callback: AuthStateChangeHandler): void {
    auth.onAuthStateChanged((user) => {
      callback(user)
    })
  }
}
