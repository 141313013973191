/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

interface TabPanelProps {
  index: any;
  value: any;
  children: any;
}

const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
  const {
    children, value, index, ...other
  } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        children
      )}
    </div>
  )
}

export default TabPanel
