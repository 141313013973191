import React, { useEffect, useState } from 'react'
import {
  Container, Paper, TextField, Typography, Checkbox,
  FormGroup, FormControlLabel, InputLabel, Select, MenuItem, FormControl,
  List, ListItem, ListItemText,
  Avatar, ListItemAvatar, ListItemSecondaryAction, IconButton,
  Dialog, DialogActions, DialogContent, DialogTitle, Button, Link as LinkUI,
} from '@material-ui/core'

import Alert from '@material-ui/lab/Alert'
import Skeleton from '@material-ui/lab/Skeleton'

import {
  Link,
} from 'react-router-dom'

import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import moment from 'moment'

import Wash from '../../../services/api/wash'

import { Address, LoadingBackdrop } from '../../../components'

import {
  ICreateOrderFormikValues, ICreateOrderTypes, IAddCarProps,
} from './types'
import useStyles from './styles'

const AddCar: React.FC<IAddCarProps> = (props: IAddCarProps) => {
  const { onSubmit, open, handleClose } = props

  const formikAddCar = useFormik({
    initialValues: {
      model: '',
      plate: '',
      color: '',
    },
    validationSchema: Yup.object({
      model: Yup.string().required('É necessário informar o modelo.'),
      plate: Yup.string().required('É necessário informar a placa.'),
      color: Yup.string().required('É necessário informar a cor.'),
    }),
    onSubmit: async (values) => {
      onSubmit(values)
      handleClose()
    },
  })

  const {
    values, handleBlur, handleChange, handleSubmit, touched, errors,
  } = formikAddCar

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="Adicionar Carro"
      fullWidth
      maxWidth="xs"
    >
      <form autoComplete="off" onSubmit={handleSubmit} noValidate>
        <DialogTitle id="form-dialog-title">Adicionar Carro</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <TextField
              fullWidth
              value={values.model}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              name="model"
              margin="normal"
              label="Modelo do Carro"
              error={!!(touched.model && errors.model)}
              helperText={
                (touched.model && errors.model)
                  ? errors.model
                  : ''
              }
            />
            <TextField
              fullWidth
              value={values.plate}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              name="plate"
              margin="normal"
              label="Placa do Carro"
              error={!!(touched.plate && errors.plate)}
              helperText={
                (touched.plate && errors.plate)
                  ? errors.plate
                  : ''
              }
            />
            <TextField
              fullWidth
              value={values.color}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              name="color"
              margin="normal"
              label="Cor do Carro"
              error={!!(touched.color && errors.color)}
              helperText={
                (touched.color && errors.color)
                  ? errors.color
                  : ''
              }
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            type="submit"
            color="primary"
          >
            Adicicionar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

const CreateOrder: React.FC = () => {
  const classes = useStyles()
  const [orderTypes, setOrderTypes] = useState<ICreateOrderTypes>([])
  const [dialogAddCar, setDialogAddCar] = useState(false)
  const [dialogAddress, setDialogAddress] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [createdOrderId, setCreatedOrderId] = useState<null | string>(null)
  const [errorMessage, setErrorMessage] = useState<null | string>(null)

  const formikPlace = useFormik<ICreateOrderFormikValues>({
    initialValues: {
      userId: '',
      hasNoUserId: false,
      userName: '',
      userPhone: '',
      schedulingDate: moment().add(2, 'hours').format('YYYY-MM-DDTkk:mm'),
      orderType: '',
      cars: [],
      address: undefined,
      companyCode: '',
      customCupomValue: '',
      customValue: '',
      hasCustomValue: false,
      customPartnerValue: '',
      hasCustomPartnerValue: false,
      
    },
    validationSchema: Yup.object({
      userId: Yup.string(),
      userName: Yup.string(),
      userPhone: Yup.string(),
      companyCode: Yup.string(),

      customValue: Yup
        .number()
        .when('hasCustomValue', {
          is: true,
          then: Yup.number().min(1).required('Campo obrigatório.'),
        }),
      customPartnerValue: Yup
        .number()
        .when('hasCustomPartnerValue', {
          is: true,
          then: Yup.number().min(10).required('Campo obrigatório.'),
        }),
    }),
    onSubmit: async (values, { setFieldError }) => {
      setCreatedOrderId(null)
      setErrorMessage(null)

      let error = false

      if (!values.cars.length) {
        setFieldError('cars', 'É necessário informar ao menos um carro!')
        error = true
      }

      if (!values.address) {
        setFieldError('address', 'É necessário informar um endereço!')
        error = true
      }

      if (!values.hasNoUserId && !values.userId) {
        setFieldError('userId', 'É necessário informar um ID de usuário!')
        error = true
      }

      if (values.hasNoUserId && !values.userName) {
        setFieldError('userName', 'É necessário informar o nome do usuário!')
        error = true
      }

      if (values.hasNoUserId && !values.userPhone) {
        setFieldError('userPhone', 'É necessário informar o número do usuário!')
        error = true
      } else if (!values.hasNoUserId && values.userPhone) {
        const ret = parsePhoneNumberFromString(values.userPhone)

        if (!ret || !ret.isValid()) {
          setFieldError('userPhone', 'Número de telefone inválido.')
          error = true
        }
      }

      if (error) {
        return
      }

      console.log(values)

      try {
        setIsLoading(true)

        const orderId = await Wash.createOrder({
          place: values.address,
          type: values.orderType,
          schedulingDate: values.schedulingDate,
          cars: values.cars,
          user: {
            id: values.userId || undefined,
            name: values.userName || undefined,
            phoneNumber: values.userPhone || undefined,
          },
          companyCode: values.companyCode || undefined,
          customPartnerValue: values.hasCustomPartnerValue ? values.customPartnerValue : undefined,
          customValue: values.hasCustomValue ? values.customValue : undefined,
          customCupomValue: values.customCupomValue ?? null
        })

        setCreatedOrderId(orderId)
      } catch (err) {
        console.error(err)

        let message = ''

        switch (err.code) {
          case 'invalid-argument': {
            message = err.details
            break
          }
          default: {
            message = 'Ocorreu algum erro, tente novamente.'
          }
        }

        setErrorMessage(message)
      } finally {
        setIsLoading(false)
      }
    },
  })

  const {
    values, setFieldValue, handleChange, handleBlur, handleSubmit, errors, touched, setFieldTouched,
  } = formikPlace

  useEffect(() => {
    (async () => {
      const response = await Wash.getOrderTypes()

      setOrderTypes(response.map((value) => ({
        id: value.id,
        title: value.title,
      })))

      setFieldValue('orderType', response[0].id)
    })()
  }, [setFieldValue])

  if (!orderTypes.length) {
    return (
      <Container className={classes.container}>
        <Skeleton variant="rect" width="100%" height={500} />
      </Container>
    )
  }

  return (
    <Container className={classes.container}>

      {(errorMessage || createdOrderId) && (
        <div style={{ marginBottom: 20 }}>
          {errorMessage && (
            <Alert severity="error">
              {errorMessage}
            </Alert>
          )}

          {createdOrderId && (
            <Alert severity="success">
              Pedido criado.
              {' '}
              <Link to={`/order/${createdOrderId}`} component={LinkUI}>Visualizar</Link>
            </Alert>
          )}
        </div>
      )}

      <LoadingBackdrop open={isLoading} />
      <Paper className={classes.root}>

        <Typography variant="h5">Criar Pedido</Typography>

        <form className={classes.form} autoComplete="off" onSubmit={handleSubmit} noValidate>
          <TextField
            name="userId"
            value={values.userId}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={values.hasNoUserId}
            label="ID do usuário"
            className={classes.textField}
            margin="normal"
            error={!!(touched.userId && errors.userId)}
            helperText={
              (touched.userId && errors.userId)
                ? errors.userId
                : ''
            }
          />

          <FormGroup row>
            <FormControlLabel
              control={(
                <Checkbox
                  onChange={() => {
                    setFieldValue('hasNoUserId', !values.hasNoUserId)
                    setFieldValue('userId', '')
                    setFieldValue('userPhone', '')
                    setFieldValue('userName', '')
                  }}
                  value={values.hasNoUserId}
                />
            )}
              label="Não possui conta"
            />
          </FormGroup>

          {values.hasNoUserId && (
            <>
              <TextField
                name="userName"
                value={values.userName}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Nome"
                className={classes.textField}
                margin="normal"
                error={!!(touched.userName && errors.userName)}
                helperText={
                  (touched.userName && errors.userName)
                    ? errors.userName
                    : ''
                }
              />

              <TextField
                name="userPhone"
                value={values.userPhone}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Celular"
                className={classes.textField}
                margin="normal"
                error={!!(touched.userPhone && errors.userPhone)}
                helperText={
                  (touched.userPhone && errors.userPhone)
                    ? errors.userPhone
                    : ''
                }
              />
            </>
          )}

          <TextField
            name="schedulingDate"
            value={values.schedulingDate}
            onChange={(event) => {
              setFieldValue('schedulingDate', event.target.value)
            }}
            onBlur={handleBlur}
            type="datetime-local"
            label="Dia e horário"
            className={classes.textField}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />

          <FormControl margin="normal">
            <InputLabel id="select-order-type-label">Tipo da lavagem</InputLabel>
            <Select
              name="orderType"
              value={values.orderType}
              onChange={handleChange}
              labelId="select-order-type-label"
              className={classes.textField}
            >
              {orderTypes.map((orderType) => (
                <MenuItem value={orderType.id} key={orderType.id}>{orderType.title}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            name="companyCode"
            value={values.companyCode}
            onChange={handleChange}
            onBlur={handleBlur}
            label="Código de empresa"
            className={classes.textField}
            margin="normal"
          />

          

          <TextField
            inputProps={{
              min: 1,
            }}
            type="number"
            name="customValue"
            value={values.customValue}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!values.hasCustomValue}
            label="Valor pedido"
            className={classes.textField}
            margin="normal"
            error={!!(touched.customValue && errors.customValue)}
            helperText={
              (touched.customValue && errors.customValue)
                ? errors.customValue
                : ''
            }
          />

          <FormGroup row>
            <FormControlLabel
              control={(
                <Checkbox
                  onChange={() => {
                    setFieldValue('customValue', '')
                    setFieldValue('hasCustomValue', !values.hasCustomValue)
                    setFieldTouched('customValue', false)
                  }}
                  value={values.hasCustomValue}
                />
            )}
              label="Customizar valor do pedido"
            />
          </FormGroup>

          <TextField
            inputProps={{
              min: 10,
            }}
            type="number"
            name="customPartnerValue"
            value={values.customPartnerValue}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!values.hasCustomPartnerValue}
            label="Valor parceiro"
            className={classes.textField}
            margin="normal"
            error={!!(touched.customPartnerValue && errors.customPartnerValue)}
            helperText={
              (touched.customPartnerValue && errors.customPartnerValue)
                ? errors.customPartnerValue
                : ''
            }
          />

          <FormGroup row>
            <FormControlLabel
              control={(
                <Checkbox
                  onChange={() => {
                    setFieldValue('customPartnerValue', '')
                    setFieldValue('hasCustomPartnerValue', !values.hasCustomPartnerValue)
                    setFieldTouched('customPartnerValue', false)
                  }}
                  value={values.hasCustomPartnerValue}
                />
            )}
              label="Customizar valor do parceiro"
            />
          </FormGroup>

          <TextField
            name="customCupomValue"
            value={values.customCupomValue}
            onChange={handleChange}
            onBlur={handleBlur}
            label="Cupom"
            className={classes.textField}
            margin="normal"
          />

          <div style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
            <Typography variant="h6">Carros</Typography>

            <IconButton color="primary" aria-label="adicionar carro" component="span" onClick={() => setDialogAddCar(true)}>
              <AddCircleOutlineIcon />
            </IconButton>

          </div>

          {dialogAddCar && (
            <AddCar
              open={dialogAddCar}
              handleClose={() => setDialogAddCar(false)}
              onSubmit={(car) => {
                setFieldValue('cars', [
                  ...values.cars,
                  {
                    ...car,
                  },
                ])
              }}
            />
          )}

          {errors.cars && (
            <Alert severity="error" style={{ marginBottom: 10 }}>
              {errors.cars}
            </Alert>
          )}

          <List className={classes.textField}>
            {!values.cars.length && (
              <div>Nenhum carro selecionado.</div>
            )}
            {values.cars.map((car) => (
              <ListItem key={car.plate}>
                <ListItemAvatar>
                  <Avatar>
                    <DirectionsCarIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  style={{ textTransform: 'uppercase' }}
                  primary={`${car.model} ${car.color}, ${car.plate}`}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => setFieldValue('cars', values.cars.filter((value) => value.plate !== car.plate))}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}

          </List>

          <div style={{
            display: 'flex', alignItems: 'center', marginTop: 5, marginBottom: 5,
          }}
          >
            <Typography variant="h6">Endereço</Typography>

            <IconButton color="primary" aria-label="adicionar endereço" component="span" onClick={() => setDialogAddress(true)}>
              {values.address ? (
                <EditIcon />
              ) : (
                <AddCircleOutlineIcon />
              )}
            </IconButton>
          </div>

          {errors.address && (
            <Alert severity="error" style={{ marginBottom: 10 }}>
              {errors.address}
            </Alert>
          )}

          {!values.address && (
            <div>Nenhum endereço selecionado.</div>
          )}

          {values.address && (
            <div>{values.address.formattedAddress}</div>
          )}

          <Address
            open={dialogAddress}
            handleClose={() => setDialogAddress(false)}
            onSubmit={(place) => {
              setFieldValue('address', place)
            }}
          />

          <div>
            <Button
              style={{ marginTop: 20 }}
              variant="contained"
              color="primary"
              type="submit"
            >
              Criar Pedido
            </Button>
          </div>
        </form>
      </Paper>
    </Container>
  )
}

export default CreateOrder
