import React from 'react'

import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'

// Theme
import Theme from './theme'

// Contexts
import { AuthProvider } from './contexts/auth'

// Routes
import ApplicationNavigation from './routes'

const App: React.FC = () => (
  <>
    <CssBaseline />
    <ThemeProvider theme={Theme}>
      <AuthProvider>
        <ApplicationNavigation />
      </AuthProvider>
    </ThemeProvider>
  </>
)

export default App
