import React, { useEffect } from 'react'
import {
  Dialog,
} from '@material-ui/core'

import ConfirmAddress from './ConfirmAddress'
import SelectAddress from './SelectAddress'

import { IAddressProps } from './types'
import { AddressProvider, useAddress } from './context'
import LoadingBackdrop from '../LoadingBackdrop'

const Content: React.FC<IAddressProps> = (props: IAddressProps) => {
  const { open, handleClose, onSubmit } = props
  const {
    screen, setOnSubmit, setHandleClose, isLoading,
  } = useAddress()

  useEffect(() => {
    setOnSubmit(() => onSubmit)
    setHandleClose(() => handleClose)
  }, [setOnSubmit, onSubmit, setHandleClose, handleClose])

  const getScreen = (): JSX.Element => {
    switch (screen) {
      case 'confirm': {
        return <ConfirmAddress />
      }
      default:
      case 'select': {
        return <SelectAddress />
      }
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="Selecionar endereço"
      fullWidth
      maxWidth="xs"
    >
      <LoadingBackdrop open={isLoading} />
      {getScreen()}
    </Dialog>
  )
}

const Address: React.FC<IAddressProps> = (props: IAddressProps) => {
  const { open, handleClose, onSubmit } = props

  // Desmontar componentes
  if (!open) { return null }

  return (
    <AddressProvider>
      <Content
        open={open}
        handleClose={handleClose}
        onSubmit={onSubmit}
      />
    </AddressProvider>
  )
}

export default Address
