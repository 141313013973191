import React, { useEffect, useState } from 'react'

import {
  Container, Paper, Typography,
  FormControlLabel,
  List, ListItem, ListItemText,
  Avatar, ListItemAvatar, ListItemSecondaryAction, IconButton,
  Button,
  Box, Grid, Skeleton, Link as LinkUI,
} from '@mui/material'

import Alert from '@material-ui/lab/Alert'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { DatePicker } from 'formik-material-ui-pickers'

import {
  useParams, useHistory, useLocation, Link,
} from 'react-router-dom'

import DeleteIcon from '@material-ui/icons/Delete'
import PersonIcon from '@material-ui/icons/Person'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

import {
  Field, Formik, Form, FormikHelpers,
} from 'formik'

import * as Yup from 'yup'

import moment from 'moment'

import { TextField, Checkbox } from 'formik-mui'
import AddBeeDialog from './AddBeeDialog'

import { IFormikValues } from './types'
import { Address } from '../../../components'
import { collections } from '../../../services/api/firebase'
import { IVoucherDoc } from '../../../services/api/voucher/types'
import Voucher from '../../../services/api/voucher'

const UpsertVoucher: React.FC = () => {
  const { id } = useParams<any>()

  const [isEditing] = useState(!!id)
  const [isLoading, setIsLoading] = useState(true)

  const history = useHistory()

  const location = useLocation() as any

  const success = location.state?.success

  const [initialValues, setInitialValues] = useState<IFormikValues>({
    code: '',
    discount: 0,
    usageLimit: 1,
    purchaseLimit: 1,
    expirationDate: moment().add(3, 'month').toDate(),
    bees: [],
    address: undefined,
    chooseRadius: false,
    radius: 30,
  })

  const [dialogAddBee, setDialogAddBee] = useState(false)
  const [dialogAddress, setDialogAddress] = useState(false)

  const [errorMessage, setErrorMessage] = useState<undefined | string>()

  const handleFormikSubmit = async (values: IFormikValues, { setSubmitting }: FormikHelpers<IFormikValues>) => {
    try {
      const voucherId = await Voucher.set({
        // Não irá alterar
        id: values.code,
        discount: values.discount,
        // Irá alterar
        usageLimit: values.usageLimit,
        expirationDate: values.expirationDate.toISOString(),
        purchaseLimit: values.purchaseLimit,
        bees: values.bees,
        radius: values.radius,
        chooseRadius: values.chooseRadius,
        address: values.address,
      }, isEditing ? 'edit' : 'create')

      history.push(`/vouchers/${voucherId}`, {
        success: isEditing ? 'edit' : 'create',
      })
    } catch (error) {
      let message: string

      switch (error.code) {
        case 'invalid-argument': {
          message = error.details
          break
        }
        default: {
          message = 'Ocorreu algum problema, entre em contato com a equipe de T.I!'
        }
      }
      setErrorMessage(message)
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    // Delete state
    window.history.replaceState({}, document.title)
  }, [location])

  useEffect(() => {
    if (!isEditing) {
      setIsLoading(false)
      return
    }

    (async () => {
      const voucher = await collections.vouchers.doc(id).get()

      if (!voucher.exists) {
        alert('Cupom não encontrado!')
        setIsLoading(false)
        history.push('/vouchers/create')
        return
      }

      const voucherData = voucher.data() as IVoucherDoc

      setInitialValues((v) => ({
        ...v,
        code: voucherData.id,
        discount: voucherData.discount,
        usageLimit: voucherData.usageLimit,
        purchaseLimit: voucherData.purchaseLimit,
        expirationDate: voucherData.expirationDate.toDate(),
        bees: voucherData.bees ?? [],
        radius: voucherData.radius,
        chooseRadius: voucherData.chooseRadius,
        address: voucherData.address,
      } as IFormikValues))

      setIsLoading(false)
    })()
  }, [isEditing, id, history])

  if (isLoading) {
    return (
      <Container>
        <Box m={5}>
          <Skeleton variant="rectangular" width="100%" height={500} />
        </Box>
      </Container>
    )
  }

  return (
    <Container>
      <Box m={5}>
        {(errorMessage || success) && (
          <Box mb={2}>
            {errorMessage && (
              <Alert severity="error">
                {errorMessage}
              </Alert>
            )}

            {success && (
              <Alert severity="success">
                Cupom
                {' '}
                {success === 'edit' ? 'editado' : 'criado'}
                {' '}
                com sucesso!
              </Alert>
            )}
          </Box>
        )}
        <Paper>
          <Box p={3}>
            <Typography variant="h5">
              {isEditing ? 'Editar' : 'Criar'}
              {' '}
              cupom
            </Typography>

            <Formik
              initialValues={initialValues}
              onSubmit={handleFormikSubmit}
              validationSchema={getSchema()}
            >
              {({
                values, setFieldValue, handleSubmit, isSubmitting,
              }) => (
                <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
                  <Grid container item xs={4}>
                    <Field
                      fullWidth
                      component={TextField}
                      name="code"
                      type="text"
                      label="Código"
                      variant="standard"
                      margin="normal"
                      disabled={isEditing}
                    />
                  </Grid>

                  <Grid container item xs={4}>
                    <Field
                      fullWidth
                      component={TextField}
                      name="discount"
                      type="number"
                      label="Desconto"
                      variant="standard"
                      margin="normal"
                      disabled={isEditing}
                    />
                  </Grid>

                  <Grid container item xs={4}>
                    <Field
                      fullWidth
                      component={TextField}
                      name="usageLimit"
                      type="number"
                      label="Quantos usuários poderão usar esse cupom?"
                      variant="standard"
                      margin="normal"
                    />
                  </Grid>

                  <Grid container item xs={4}>
                    <Field
                      fullWidth
                      component={TextField}
                      name="purchaseLimit"
                      type="number"
                      label="Quantas vezes o usuário poderá comprar com esse cupom?"
                      variant="standard"
                      margin="normal"
                    />
                  </Grid>

                  <Grid container item xs={4}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <Field
                        label="Data de expiração"
                        fullWidth
                        component={DatePicker}
                        name="expirationDate"
                        margin="normal"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  {/* Add bee */}
                  <Address
                    open={dialogAddress}
                    handleClose={() => setDialogAddress(false)}
                    onSubmit={(place) => {
                      console.log(place)
                      setFieldValue('address', place)
                    }}
                  />

                  <AddBeeDialog
                    open={dialogAddBee}
                    handleClose={() => setDialogAddBee(false)}
                    onSubmit={(bee) => {
                      setFieldValue('bees', [...values.bees, bee])
                    }}
                  />

                  <Box display="flex" flexDirection="row" alignItems="center" mt={2}>
                    <Typography variant="h6">Bees atendentes</Typography>
                    <IconButton
                      color="primary"
                      aria-label="adicionar carro"
                      component="span"
                      onClick={() => setDialogAddBee(true)}
                      disabled={isSubmitting}
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </Box>

                  <Grid container item xs={6}>
                    <List>
                      {!values.bees.length && (
                        <Typography>Nenhum bee selecionado.</Typography>
                      )}
                      {values.bees.map((bee) => (
                        <ListItem key={bee.id}>
                          <ListItemAvatar>
                            <Avatar>
                              <PersonIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <Link to={`/user/${bee.id}`} component={LinkUI}>
                            <ListItemText
                              primary={bee.name}
                            />
                          </Link>
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => setFieldValue('bees', values.bees.filter((value) => value.id !== bee.id))}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>

                  { /* Add Address */ }
                  <Box display="flex" flexDirection="row" alignItems="center" mt={2}>
                    <Typography variant="h6">Endereço de atendimento</Typography>
                    <IconButton
                      color="primary"
                      aria-label="adicionar endereço"
                      component="span"
                      onClick={() => {
                        if (values.address) {
                          setFieldValue('address', undefined)
                        } else {
                          setDialogAddress(true)
                        }
                      }}
                      disabled={isSubmitting}
                    >
                      {values.address ? (
                        <DeleteIcon />
                      ) : (
                        <AddCircleOutlineIcon />
                      )}
                    </IconButton>
                  </Box>

                  {!values.address && (
                    <Typography>Nenhum endereço selecionado.</Typography>
                  )}

                  {values.address && (
                    <>
                      <Typography>{values.address.formattedAddress}</Typography>

                      <FormControlLabel
                        control={(
                          <Field
                            component={Checkbox}
                            type="checkbox"
                            name="chooseRadius"
                            margin="normal"
                          />
                         )}
                        label="Especificar raio a partir deste endereço"
                      />

                      {values.chooseRadius && (
                        <Grid container item xs={4}>
                          <Field
                            fullWidth
                            component={TextField}
                            name="radius"
                            type="number"
                            label="Raio (km)"
                            variant="standard"
                            margin="normal"
                          />
                        </Grid>
                      )}
                    </>
                  )}
                  <Box mt={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      {isEditing ? 'Editar' : 'Criar'}
                      {' '}
                      cupom
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Paper>
      </Box>
    </Container>
  )
}

export function getSchema() {
  return Yup.object({
    code: Yup.string().required(),
    discount: Yup.number().min(0.1).required(),
    usageLimit: Yup.number().min(1).required(),
    purchaseLimit: Yup.number().min(1).required(),
    expirationDate: Yup.date().required(),
    radius: Yup
      .number()
      .when('chooseRadius', {
        is: true,
        then: Yup.number().min(1).required(),
      }),

  })
}

export default UpsertVoucher
