import {
  functions, collections, firebase,
} from '../firebase'

import { ICompanyCodeSetRequestDTO } from './types'

type SnapshotCallback = (
  snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
) => void

const companyCode = {
  getAll: (callback: SnapshotCallback) => {
    const unsubscribe = collections.companiesCodes
      .onSnapshot((snapshot) => {
        callback(snapshot)
      })

    return unsubscribe
  },
  set: async (data: ICompanyCodeSetRequestDTO) => {
    const cloudSetCompanyCode = functions.httpsCallable('setCompanyCode')
    await cloudSetCompanyCode(data)
  },
  delete: async (code: string) => {
    const cloudDeleteCompanyCode = functions.httpsCallable('deleteCompanyCode')
    await cloudDeleteCompanyCode({ code })
  },
}

export default companyCode
