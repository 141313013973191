import React, {
  createContext, useState, useContext, useEffect,
} from 'react'

import { Auth, User } from '../services/api'
import { auth } from '../services/api/firebase'

interface UserData {
  uid: string;
  name: string;
  email: string;
  phoneNumber: string;
  admin: number;
}

interface AuthContextData {
  user: UserData | null;
  signed: boolean;
  appLoaded: boolean;
}

// Contexts
const AuthContext = createContext<AuthContextData>({} as AuthContextData)

export const AuthProvider: React.FC = (props) => {
  // States
  const [user, setUser] = useState<UserData | null>(null)
  const [appLoaded, setAppLoaded] = useState(false)

  useEffect(() => {
    // Necessário se não o snapshot continuará stremando mesmo após o sign-out do user.
    let unsubscribeSnapshot: any = () => {}

    const unsubscribe = auth.onAuthStateChanged(async (userCred) => {
      if (userCred) {
        const { uid } = userCred

        unsubscribeSnapshot = User.getUserSnapshot(uid, async (snapshot) => {
          // Atualizar custom claims
          await auth.currentUser?.getIdToken(true)

          const userDoc = snapshot.data()

          if (!userDoc) {
            await Auth.signOut()
            return
          }

          setUser({
            uid,
            name: userDoc.displayName,
            email: userDoc.email,
            phoneNumber: userDoc.phoneNumber,
            admin: userDoc.admin || 0,
          })

          setAppLoaded(true)
        })

        return
      }

      setUser(null)
      setAppLoaded(true)
      unsubscribeSnapshot()
    })

    return () => {
      unsubscribeSnapshot()
      unsubscribe()
    }
  }, [])

  const { children } = props
  return (
    <AuthContext.Provider
      value={{
        user,
        signed: !!user,
        appLoaded,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = (): AuthContextData => {
  const context = useContext(AuthContext)
  return context
}
