/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'

import {
  Paper, Tabs, Tab,
} from '@material-ui/core'

import { extendMoment } from 'moment-range'

import { ResponsivePie } from '@nivo/pie'

import { ResponsiveLine } from '@nivo/line'

import Wash, { WashingDoc } from '../../services/api/wash'

import TabPanel from './components/TabPanel'

import useStyles from './styles'

const Moment = require('moment')

const moment = extendMoment(Moment)

type IDateOptions = 'today' | 'weekly' | 'monthly' | 'last-month'

interface IGetDateFromToResponse {
  dateFrom: Date;
  dateTo: Date;
}

interface ITable {
  waitingPayment: number;
  waitingConfirmation: number;
  canceled: number;
  confirmed: number;
  completed: number;
  total: number;
}

interface IData {
  status: string;
  createdDate: Date;
  schedulingDate: Date;
}

interface ILine {
  created: {
    x: string;
    y: number;
  }[];
  washes: {
    x: string;
    y: number;
  }[];
  canceled: {
    x: string;
    y: number;
  }[];
}

const a11yProps = (index: any): any => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
})

const getDateFromTo = (option: IDateOptions): IGetDateFromToResponse => {
  let dateFrom: Date
  let dateTo: Date

  switch (option) {
    case 'today': {
      dateFrom = moment().set({ hour: 0, minute: 0, seconds: 0 }).toDate()
      dateTo = moment().set({ hour: 23, minute: 59, seconds: 59 }).toDate()
      break
    }
    case 'weekly': {
      dateFrom = moment().startOf('week').toDate()
      dateTo = moment().endOf('week').toDate()
      break
    }
    case 'monthly': {
      dateFrom = moment().startOf('month').toDate()
      dateTo = moment().endOf('month').toDate()
      break
    }
    default:
    case 'last-month': {
      dateFrom = moment().subtract(1, 'months').startOf('month').toDate()
      dateTo = moment().subtract(1, 'months').endOf('month').toDate()
      break
    }
  }

  return {
    dateFrom,
    dateTo,
  }
}

const Statistics: React.FC = () => {
  const classes = useStyles()
  const [tabValue, setTabValue] = useState(0)
  const [table, setTable] = useState<ITable>({} as ITable)
  const [data, setData] = useState<IData[]>([])
  const [filter, setFilter] = useState<IDateOptions>('today')
  const [lineData, setLineData] = useState<ILine>({
    created: [],
    washes: [],
    canceled: [],
  })

  const [lastMonthDate] = useState(moment().subtract(1, 'months').startOf('month').toDate())

  useEffect(() => {
    Wash.collection()
      .where('date', '>', lastMonthDate)
      .onSnapshot((snapshot) => {
        const list = snapshot.docs
          .map((doc) => {
            const orderDoc = {
              ...doc.data(),
              uid: doc.id,
            } as WashingDoc

            return {
              status: orderDoc.status,
              createdDate: orderDoc.createdAt.toDate(),
              schedulingDate: orderDoc.date.toDate(),
            } as IData
          })

        setData(list)
      })
  }, [lastMonthDate])

  useEffect(() => {
    const { dateFrom, dateTo } = getDateFromTo(filter as any)

    const dataFilteredByDate = data.filter((value) => moment(value.schedulingDate).isBetween(dateFrom, dateTo))

    const completed = dataFilteredByDate.filter((value) => value.status === 'completed').length

    const waitingPayment = dataFilteredByDate.filter((value) => value.status === 'waiting-for-payment').length

    const waitingConfirmation = dataFilteredByDate.filter((value) => value.status === 'waiting-for-partner-to-accept').length

    const confirmed = dataFilteredByDate.filter((value) => value.status === 'waiting-for-partner'
      || value.status === 'partner-on-the-way'
      || value.status === 'partner-arrived').length

    const canceled = dataFilteredByDate.filter((value) => value.status === 'canceled').length

    const total = dataFilteredByDate.length

    setTable({
      waitingPayment,
      waitingConfirmation,
      confirmed,
      completed,
      canceled,
      total,
    })
  }, [data, filter])

  useEffect(() => {
    const range = moment.range(lastMonthDate, moment())

    const dates = Array.from(range.by('days'))

    const countCreatedOrders = (): void => {
      const list = dates.map((value) => ({
        x: moment(value).format('YYYY-MM-DD'),
        y: 0,
      }))

      data.forEach(({ createdDate }) => {
        list.forEach(({ x }, index) => {
          const ret = moment(createdDate).isSame(x, 'date')

          if (ret) {
            list[index].y += 1
          }
        })
      })

      setLineData((values) => ({
        ...values,
        created: list,
      }))
    }

    const countWashes = (): void => {
      const list = dates.map((value) => ({
        x: moment(value).format('YYYY-MM-DD'),
        y: 0,
      }))

      data.forEach(({ schedulingDate, status }) => {
        list.forEach(({ x }, index) => {
          const ret = moment(schedulingDate).isSame(x, 'date') && status === 'completed'

          if (ret) {
            list[index].y += 1
          }
        })
      })

      setLineData((values) => ({
        ...values,
        washes: list,
      }))
    }

    const countCanceled = (): void => {
      const list = dates.map((value) => ({
        x: moment(value).format('YYYY-MM-DD'),
        y: 0,
      }))

      data.forEach(({ createdDate, status }) => {
        list.forEach(({ x }, index) => {
          const ret = moment(createdDate).isSame(x, 'date') && status === 'canceled'

          if (ret) {
            list[index].y += 1
          }
        })
      })

      setLineData((values) => ({
        ...values,
        canceled: list,
      }))
    }

    countCreatedOrders()
    countWashes()
    countCanceled()
  }, [data, lastMonthDate])

  return (
    <>
      <Paper>
        <div className={classes.root}>
          <Tabs
            value={tabValue}
            onChange={(event, value) => setTabValue(value)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Hoje" {...a11yProps(0)} onClick={() => setFilter('today')} />
            <Tab label="Semana atual" {...a11yProps(0)} onClick={() => setFilter('weekly')} />
            <Tab label="Mês atual" {...a11yProps(0)} onClick={() => setFilter('monthly')} />
            <Tab label="Mês passado" {...a11yProps(0)} onClick={() => setFilter('last-month')} />
          </Tabs>
        </div>

        {['today', 'weekly', 'monthly', 'last-month'].map((value, index) => (
          <TabPanel value={tabValue} index={index} key={value}>
            <div style={{
              height: 500,
              width: '100%',
              marginBottom: 30,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            >
              <ResponsivePie
                data={[
                  {
                    id: 'Aguardado pagamento',
                    label: 'Aguardado pagamento',
                    value: table.waitingPayment,
                    color: '#8B8B8B',
                  },
                  {
                    id: 'Aguardando confirmação',
                    label: 'Aguardando confirmação',
                    value: table.waitingConfirmation,
                    color: '#F6C862',
                  },
                  {
                    id: 'Agendamento confirmado',
                    label: 'Agendamento confirmado',
                    value: table.confirmed,
                    color: '#70CE87',
                  },
                  {
                    id: 'Finalizado',
                    label: 'Finalizado',
                    value: table.completed,
                    color: '#E0E0E0',
                  },
                  {
                    id: 'Cancelado',
                    label: 'Cancelado',
                    value: table.canceled,
                    color: '#F36765',
                  },
                ]}
                margin={{
                  top: 40, right: 80, bottom: 80, left: 80,
                }}
                valueFormat=" >-"
                innerRadius={0.5}
                activeOuterRadiusOffset={8}
                colors={{ datum: 'data.color' }}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                enableArcLinkLabels={false}
                legends={[
                  {
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 80,
                    itemsSpacing: 0,
                    itemWidth: 150,
                    itemHeight: 50,
                    itemTextColor: '#999',
                    itemDirection: 'top-to-bottom',
                    itemOpacity: 1,

                    symbolSize: 18,
                    symbolShape: 'circle',
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemTextColor: '#000',
                        },
                      },
                    ],
                  },
                ]}
              />
            </div>
          </TabPanel>
        ))}
      </Paper>

      <Paper style={{ marginTop: 30, padding: 20 }}>
        <h1 style={{ textAlign: 'center' }}>
          {moment(lastMonthDate).format('MMMM')}
          {' '}
          |
          {' '}
          {moment().format('MMMM')}
        </h1>
        <div style={{
          height: 500,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        >
          <ResponsiveLine
            data={[
              {
                id: 'Pedidos Criados',
                data: lineData.created,
                color: 'green',
              },
              {
                id: 'Pedidos Cancelados',
                data: lineData.canceled,
                color: 'red',
              },
              {
                id: 'Lavagens Realizadas',
                data: lineData.washes,
                color: 'blue',
              },
            ]}
            margin={{
              top: 50, right: 60, bottom: 50, left: 60,
            }}
            xScale={{
              type: 'time',
              format: '%Y-%m-%d',
              useUTC: false,
              precision: 'day',
            }}
            xFormat="time:%B %d, %Y"
            yScale={{
              type: 'linear',
              min: 'auto',
              max: 'auto',
              stacked: false,
              reverse: false,
            }}
            enableSlices="x"
            sliceTooltip={({ slice }) => (
              <div
                style={{
                  background: 'white',
                  padding: '9px 12px',
                  border: '1px solid #ccc',
                }}
              >
                <p>{slice.points[0].data.xFormatted}</p>
                {slice.points.map((point) => (
                  <div
                    key={point.id}
                    style={{
                      color: point.serieColor,
                      padding: '3px 0',
                    }}
                  >
                    <strong>{point.serieId}</strong>
                    {' '}
                    [
                    {point.data.yFormatted}
                    ]
                  </div>
                ))}
              </div>
            )}
            axisTop={null}
            axisRight={null}
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendOffset: -40,
              legendPosition: 'middle',
            }}
            axisBottom={{
              format: '%b %d',
              // tickValues: "every 2 days",
              // tickRotation: -90,
              legendOffset: -12,
            }}
            colors={{ scheme: 'nivo' }}
            useMesh
            legends={[
              {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 54,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 150,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemBackground: 'rgba(0, 0, 0, .03)',
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </div>
      </Paper>
    </>
  )
}

export default Statistics
