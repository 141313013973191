import React, { useState, useEffect } from 'react'
import {
  Container, Grid, Typography,
} from '@material-ui/core'

import MaterialTable, { Column } from '@material-table/core'

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

import moment from 'moment'

import {
  Link,
} from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import { Statistics } from '../../components'

import Wash, { WashingDoc } from '../../services/api/wash'

// Components
import OrdersForToday from './components/OrdersForToday'

interface Row {
  uid: string;
  orderId: number;
  client: {
    name: string;
    uid: string;
  };
  product: {
    id: string;
    quantity: number;
    unitPrice: number;
  };
  status: {
    name: string;
    color: string;
  };
  createdDate: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
  },
}))

const Home: React.FC = () => {
  const classes = useStyles()
  const [list, setList] = useState<Row[]>([])

  const columns: Column<Row>[] = [
    {
      title: 'Identificação',
      render: (data) => (
        <div>
          Pedido
          {' '}
          {data.orderId}
          {' '}
          -
          {' '}
          {data.client.name}
        </div>
      ),
    },
    {
      title: 'Produto',
      render: (data) => (
        <div>
          {data.product.quantity}
          {'x '}
          {Wash.getTypeName(data.product.id)}
        </div>
      ),
    },
    {
      title: 'Status',
      field: 'status',
      lookup: {
        'waiting-for-payment': 'Aguardando pagamento',
        'waiting-for-partner-to-accept': 'Aguardando confirmação',
        'waiting-for-partner': 'Agendamento confirmado',
        'partner-on-the-way': 'A caminho',
        'partner-arrived': 'No local',
        completed: 'Finalizado',
        canceled: 'Cancelado',
      },
      render: (data) => (
        <Grid container alignItems="center">
          <FiberManualRecordIcon style={{ color: data.status.color, marginRight: 5 }} />
          {data.status.name}
        </Grid>
      ),
    },
    {
      title: 'Criado em',
      field: 'createdDate',
      editable: 'never',
      type: 'datetime',
      defaultSort: 'desc',
      filtering: false,
      render: (row) => {
        const fromNow = moment(row.createdDate).fromNow()

        return (<div>{fromNow}</div>)
      },
    },
  ]

  useEffect(() => {
    const dateFrom = moment().set({ hour: 0, minute: 0, seconds: 0 }).toDate()
    const dateTo = moment().set({ hour: 23, minute: 59, seconds: 59 }).toDate()

    Wash.collection()
      .where('createdAt', '>', dateFrom)
      .where('createdAt', '<', dateTo)
      .onSnapshot((snapshot) => {
        const items = snapshot.docs.map((doc) => {
          const orderDoc = {
            ...doc.data(),
            uid: doc.id,
          } as WashingDoc

          const item: Row = {
            uid: orderDoc.uid ?? '',
            client: {
              name: orderDoc.user.displayName,
              uid: orderDoc.user.id,
            },
            orderId: orderDoc.createdAt.seconds,
            status: {
              name: Wash.getStatusName(orderDoc.status),
              color: Wash.getStatusColor(orderDoc.status),
            },
            createdDate: orderDoc.createdAt.toMillis(),
            product: {
              id: orderDoc.type.id,
              quantity: orderDoc.cars?.length ?? 1,
              unitPrice: orderDoc.type.price,
            },
          }

          return item
        })

        setList(items)
      })
  }, [])

  return (
    <Container className={classes.root}>
      <Grid container justify="center">
        <Grid item xs={12} style={{ marginBottom: 50 }}>
          <Statistics />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 50 }}>
          <MaterialTable
            title={(
              <Grid container alignItems="center">
                <Typography variant="h6">Pedidos criados hoje</Typography>
                <Typography style={{ paddingLeft: 10 }}>
                  <Link to="/orders">
                    ver todos os pedidos
                  </Link>
                </Typography>
              </Grid>
              )}
            columns={columns}
            data={list}
            options={{
              actionsColumnIndex: -1,
              sorting: true,
              filtering: true,
            }}
            localization={{
              toolbar: {
                searchTooltip: 'Pesquisar',
                searchPlaceholder: 'Pesquisar',
              },
              body: {
                emptyDataSourceMessage: 'Sem resultados para mostrar',
              },
              pagination: {
                labelRowsSelect: 'linhas',
                labelDisplayedRows: '{from}-{to} de {count}',
              },
            }}
          />
        </Grid>
        <OrdersForToday />
      </Grid>
    </Container>
  )
}

export default Home
