/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'

import {
  Container, Grid,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import MaterialTable, { Column } from '@material-table/core'

import Skeleton from '@material-ui/lab/Skeleton'

// Api
import companyCode from '../../services/api/companyCode'
import { ICompanyCode } from './types'
import { ICompanyCodeDoc } from '../../services/api/companyCode/types'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(8, 0, 8),
  },
}))

const CompaniesCodes: React.FC = () => {
  const classes = useStyles()

  // States
  const columns: Column<ICompanyCode>[] = [
    {
      title: 'Código',
      field: 'code',
      editable: 'onAdd',
    },
    {
      title: 'Nome da Empresa',
      field: 'companyName',
      editable: 'always',
    },
    {
      title: 'Email',
      field: 'email',
      editable: 'always',
    },
    {
      title: 'Limite Mensal',
      field: 'monthlyLimit',
      initialEditValue: '0',
      editable: 'always',
    },
  ]

  const [list, setList] = useState<ICompanyCode[]>([])
  const [loadingList, setLoadingList] = useState(true)

  // Effects

  useEffect(() => {
    const unsubscribe = companyCode.getAll((snapshot) => {
      const changes = snapshot.docChanges()

      changes.forEach((change) => {
        const voucherDoc = change.doc.data() as ICompanyCodeDoc

        const item: ICompanyCode = {
          code: change.doc.id,
          companyName: voucherDoc.companyName,
          monthlyLimit: voucherDoc.monthlyLimit,
          email: voucherDoc.email,
        }

        if (change.type === 'added') {
          setList((o) => {
            // Se o user já estiver presente no lista, não fazer nada.
            if (o.find((value) => value.code === item.code)) {
              return [...o]
            }

            return [...o, item]
          })
        } else if (change.type === 'removed') {
          setList((o) => o.filter((value) => value.code !== item.code))
        } else if (change.type === 'modified') {
          setList((o) => o.map((value) => (value.code === item.code ? item : value)))
        }
      })

      setLoadingList(false)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Grid container justify="center">
        <Grid item xs={12} lg={10}>
          {loadingList ? (
            <Skeleton variant="rect" width="100%" height={500} />
          ) : (
            <MaterialTable
              title="Lista de Códigos"
              columns={columns}
              data={list}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                sorting: true,
              }}
              localization={{
                header: {
                  actions: '',
                },
                toolbar: {
                  searchTooltip: 'Pesquisar',
                  searchPlaceholder: 'Pesquisar',
                  exportTitle: 'Exportar',
                  exportAriaLabel: 'Exportar',
                },
                body: {
                  emptyDataSourceMessage: 'Sem resultados para mostrar',
                },
                pagination: {
                  labelRowsSelect: 'linhas',
                  labelDisplayedRows: '{from}-{to} de {count}',
                },
              }}
              editable={{
                onRowUpdate: async (newData) => {
                  try {
                    await companyCode.set({
                      code: newData.code,
                      companyName: newData.companyName,
                      monthlyLimit: Number(newData.monthlyLimit),
                      email: newData.email,
                    })
                  } catch (err) {
                    alert('Ocorreu algum problema, entre em contato com a equipe de T.I!')
                  }
                },
                onRowAdd: async (newData) => {
                  try {
                    await companyCode.set({
                      code: newData.code,
                      companyName: newData.companyName,
                      monthlyLimit: Number(newData.monthlyLimit),
                      email: newData.email,
                    })
                  } catch (err) {
                    let errorMessage = ''
                    switch (err.code) {
                      case 'invalid-argument': {
                        errorMessage = err.message
                        break
                      }
                      default: {
                        errorMessage = 'Ocorreu algum problema, entre em contato com a equipe de T.I!'
                      }
                    }
                    alert(errorMessage)
                  }
                },
                onRowDelete: async (oldData) => {
                  try {
                    await companyCode.delete(oldData.code)
                  } catch (err) {
                    alert('Ocorreu algum problema, por favor tente novamente.')
                  }
                },
              }}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default CompaniesCodes
