/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'

import {
  Container, Grid, Link as LinkUI,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import MaterialTable, { Column } from '@material-table/core'

import Skeleton from '@material-ui/lab/Skeleton'

import {
  Link,
} from 'react-router-dom'
import { collections } from '../../services/api/firebase'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(8, 0, 8),
  },
}))

interface Row {
  id: string;
  name: number;
  lastUpdate: Date;
}

export const AbandonedCarts: React.FC = () => {
  const classes = useStyles()

  const [columns] = useState<Column<Row>[]>([
    {
      title: 'Usuário',
      field: 'id',
      render: (row) => (
        <Link to={`/user/${row.id}`} component={LinkUI}>{row.name}</Link>
      ),
    },
    {
      title: 'Última atualização',
      field: 'lastUpdate',
      type: 'datetime',
      defaultSort: 'desc',
      render: (row) => {
      // Usar depois moment js!
        const option = {
          year: 'numeric',
          month: ('short' || 'numeric'),
          weekday: ('long' || 'short'),
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }

        const lastUpdate = new Date(row.lastUpdate).toLocaleDateString('pt-br', option as any)

        return (<div>{lastUpdate}</div>)
      },
    },
  ])

  const [list, setList] = useState<Row[]>([])
  const [loadingList, setLoadingList] = useState(true)

  // Effects

  useEffect(() => {
    const unsubscribe = collections.users.where('cart.finished', '==', false).onSnapshot((snapshot) => {
      const items = snapshot.docs.map((doc) => {
        const item: Row = {
          id: doc.id,
          name: doc.data().displayName,
          lastUpdate: doc.data().cart.lastUpdate.toDate(),
        }

        return item
      })

      setList(items)

      setLoadingList(false)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Grid container justify="center">
        <Grid item xs={12} lg={6}>
          {loadingList ? (
            <Skeleton variant="rect" width="100%" height={500} />
          ) : (
            <MaterialTable
              title="Carrinhos abandonados"
              columns={columns}
              data={list}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                sorting: true,
              }}
              localization={{
                header: {
                  actions: '',
                },
                toolbar: {
                  searchTooltip: 'Pesquisar',
                  searchPlaceholder: 'Pesquisar',
                  exportTitle: 'Exportar',
                  exportAriaLabel: 'Exportar',
                },
                body: {
                  emptyDataSourceMessage: 'Sem resultados para mostrar',
                },
                pagination: {
                  labelRowsSelect: 'linhas',
                  labelDisplayedRows: '{from}-{to} de {count}',
                },
              }}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  )
}
