import {
  Container, Paper, TextField, Typography, InputAdornment, TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
import moment from 'moment'

import React, { useEffect, useState } from 'react'
import { collections } from '../../services/api/firebase'
import currency from '../../utils/currency'

import TableCities from './components/TableCities'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(3),
  },
}))

enum Constants {
  CREDIT_CARD_FEE = 3.19, // Porcantagem
  ANTI_FRAUDE_VALUE = 0.40, // R$
  ANTECIPATION_FEE = 1.0, // Porcantagem
  BOLETO_FEE = 2.5, // R$
  PICPAY_FEE = 3.89, // Porcentagem
  TICKET_LOG_FEE = 5.5, // Porcentagem
}

interface ITable {
  sales: string;
  fees: string;
  partner: string;
  voucher: string;
  total: string;
  b2c: number;
  b2b: number;
  washes: number;
}

interface IFilter {
  from: Date;
  to: Date;
}

interface IOrderByCity {
  washes: number;
  city: string;
  sales: number;
}

const Financial: React.FC = () => {
  const classes = useStyles()
  const [table, setTable] = useState<ITable>({} as ITable)
  const [ordersByCities, setOrdersByCities] = useState<IOrderByCity[]>([])
  const [filter, setFilter] = useState<IFilter>({
    from: moment().startOf('month').toDate(),
    to: moment().endOf('month').toDate(),
  })
  const [totalUsers, setTotalUsers] = useState(0)
  const [uniqueUsersWhoBought, setUniqueUsersWhoBought] = useState(0)
  const [uniqueUsersWhoBoughtAgain, setUniqueUsersWhoBoughtAgain] = useState(0)

  useEffect(() => {
    (async () => {
      const [ordersSnap, usersSnap] = await Promise.all([
        collections.orders.get(),
        collections.users.get(),
      ])

      const orders = ordersSnap.docs.map((doc) => ({
        userId: doc.data().user.id,
        status: doc.data().status,
      }))// .filter((order) => order.status === 'completed')

      const users = usersSnap.docs.map((doc) => ({
        userId: doc.id,
        partner: doc.data().partner ?? null,
      })).filter((user) => !user.partner)

      let bought = 0
      let boughtAgain = 0
      users.forEach((user) => {
        const ret = orders.filter((order) => order.userId === user.userId)

        if (ret.length) {
          bought += 1
        }

        if (ret.length > 1) {
          boughtAgain += 1
        }
      })

      setTotalUsers(users.length)
      setUniqueUsersWhoBought(bought)
      setUniqueUsersWhoBoughtAgain(boughtAgain)
    })()
  }, [])

  useEffect(() => {
    (async () => {
      const orders = await collections.orders
        .where('date', '>', filter.from)
        .where('date', '<', filter.to)
        .get()

      const firstStage = orders.docs.filter((doc) => {
        const orderDoc = doc.data()
        return orderDoc.status === 'completed'
      })

      const secondStage = firstStage.map((doc) => {
        const orderDoc = doc.data()

        const isB2b = !!orderDoc.company
        const isB2c = !isB2b

        const getPaymentAmount = (): number => {
          if (isB2b && orderDoc.paymentDetails.amount === 0) {
            if (orderDoc.company.name === 'Ype') {
              return 48
            }

            return 55
          }

          return orderDoc.paymentDetails.amount / 100
        }

        const paymentAmount = getPaymentAmount()
        const paymentAmountWithoutDiscount = orderDoc.paymentDetails.amountWithoutDiscount / 100

        const partnerAmount = orderDoc.partner.amount / 100

        const getFees = (): number => {
          const { gateway, method } = orderDoc.paymentDetails

          if (method == null) {
            return 0
          }

          switch (method) {
            /**
             * Meios de pagamento
             */
            case 'credit-card': {
              switch (gateway ?? '') {
                /**
                 * Gateways dentro de cartão de crédito
                 */
                case 'ticket-log': {
                  return paymentAmount * (Constants.TICKET_LOG_FEE / 100)
                }
                default: {
                  return (paymentAmount * (Constants.CREDIT_CARD_FEE / 100) + Constants.ANTI_FRAUDE_VALUE)
                }
              }
            }
            case 'picpay': {
              return paymentAmount * (Constants.PICPAY_FEE / 100)
            }
            case 'boleto': {
              return Constants.BOLETO_FEE
            }
            default: {
              return 0
            }
          }
        }

        const discount = orderDoc.voucher
          ? ((orderDoc.voucher.discount / 100) * paymentAmountWithoutDiscount)
          : 0

        const fees = getFees()

        return {
          amount: paymentAmount,
          partner: partnerAmount,
          voucher: discount,
          fees,
          isB2b,
          isB2c,
          cars: orderDoc.cars?.length ?? 1,
          city: orderDoc.place ? `${orderDoc.place.city} - ${orderDoc.place.state}` : 'N/A',
        }
      })

      // setData(secondStage)

      if (!secondStage.length) {
        setTable({
          sales: currency.formatMoney(0),
          partner: currency.formatMoney(0),
          voucher: currency.formatMoney(0),
          fees: currency.formatMoney(0),
          total: currency.formatMoney(0),
          washes: 0,
          b2b: 0,
          b2c: 0,
        })
        return
      }

      const thirdStage = secondStage.reduce((accumalator, current) => ({
        sales: accumalator.sales + current.amount,
        partner: accumalator.partner + current.partner,
        voucher: accumalator.voucher + current.voucher,
        fees: accumalator.fees + current.fees,
        b2b: current.isB2b ? accumalator.b2b + current.cars : accumalator.b2b,
        b2c: current.isB2c ? accumalator.b2c + current.cars : accumalator.b2c,
        cars: accumalator.cars + current.cars,
      }), {
        sales: 0,
        partner: 0,
        voucher: 0,
        fees: 0,
        b2b: 0,
        b2c: 0,
        cars: 0,
      })

      const sales = thirdStage.sales + thirdStage.voucher

      setTable({
        sales: currency.formatMoney(thirdStage.sales + thirdStage.voucher),
        partner: currency.formatMoney(thirdStage.partner),
        voucher: currency.formatMoney(thirdStage.voucher),
        fees: currency.formatMoney(thirdStage.fees),
        total: currency.formatMoney(sales - thirdStage.partner - thirdStage.voucher - thirdStage.fees),
        washes: thirdStage.cars,
        b2b: thirdStage.b2b,
        b2c: thirdStage.b2c,
      })

      const byCity = secondStage.filter((value) => value.isB2c).reduce((acc, curr) => {
        const key = curr.city

        return {
          ...acc,
          [key]: [...(acc[key] || []), curr],
        }
      }, {} as any)

      console.log(byCity)

      const byCityConverted = Object.keys(byCity).map((key) => ({
        city: key,
        washes: byCity[key].length,
        sales: byCity[key].reduce((acc: any, curr: any) => acc + curr.amount, 0),
      }))

      setOrdersByCities(byCityConverted)
    })()
  }, [filter])

  const values = {
    from: moment(filter.from).format('YYYY-MM-DD'),
    to: moment(filter.to).format('YYYY-MM-DD'),
  }

  if (!table) {
    return (
      <Container className={classes.root}>
        <Skeleton variant="rect" width="100%" height={500} />
      </Container>
    )
  }

  return (
    <Container>
      <Paper className={classes.root}>

        <div style={{ marginBottom: 16 }}>
          <div>
            De
            {' '}
            {totalUsers}
            {' '}
            usuários cadastrados (excluindo bees),
            {' '}
            {uniqueUsersWhoBought}
            {' '}
            compraram.
          </div>

          <div>
            De
            {' '}
            {uniqueUsersWhoBought}
            {' '}
            usuários que compraram,
            {' '}
            {uniqueUsersWhoBoughtAgain}
            {' '}
            compraram duas vezes.
          </div>

          <div style={{ marginTop: 5 }}>
            * Dados de todo o período
          </div>
          <div>
            * Contabilizando como compra mesmo se o pedido não foi finalizado.
          </div>
        </div>

        <TextField
          style={{ marginRight: 20 }}
          type="date"
          value={values.from}
          onChange={(event) => {
            event.persist()

            setFilter((value) => ({
              ...value,
              from: moment(event.target.value)
                .hours(0)
                .minutes(0)
                .seconds(0)
                .toDate(),
            }))
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography>De</Typography>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          type="date"
          value={values.to}
          onChange={(event) => {
            event.persist()

            setFilter((value) => ({
              ...value,
              to: moment(event.target.value)
                .hours(23)
                .minutes(59)
                .seconds(59)
                .toDate(),
            }))
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography>Até</Typography>
              </InputAdornment>
            ),
          }}
        />

        <div style={{ marginTop: 20 }}>
          <p>
            {table.b2c}
            {' '}
            b2c.
          </p>
          <p>
            {table.b2b}
            {' '}
            b2b.
          </p>
          <p>
            Total de
            {' '}
            {table.washes}
            {' '}
            lavagens finalizadas.
          </p>
        </div>

        <TableContainer component={Paper} style={{ marginTop: 20 }}>
          <Table aria-label="spanning table">
            <TableHead>
              <TableRow>
                <TableCell>Descrição</TableCell>
                <TableCell>Valor</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Vendas</TableCell>
                <TableCell>{table.sales}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>Taxas</TableCell>
                <TableCell>
                  {table.fees}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>Cupons</TableCell>
                <TableCell>

                  {table.voucher}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>Parceiros</TableCell>
                <TableCell>

                  {table.partner}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>Total</TableCell>
                <TableCell>{table.total}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TableCities data={ordersByCities} />

      </Paper>

    </Container>
  )
}

export default Financial
