import React, { useEffect, useState } from 'react'
import MaterialTable, { Column } from '@material-table/core'
import currency from '../../../../utils/currency'

interface IProps {
  data: {
    washes: number;
    city: string;
    sales: number;
  }[];
}

interface IRow {
  city: string;
  washes: number;
  sales: number;
}

const TableCities: React.FC<IProps> = (props: IProps) => {
  const { data } = props

  const [list, setList] = useState<IRow[]>([])

  const [columns] = useState<Column<IRow>[]>([
    {
      field: 'city',
      title: 'Cidade',
    },
    {
      field: 'washes',
      title: 'Lavagens',
      filtering: false,
    },
    {
      field: 'sales',
      title: 'Vendas',
      filtering: false,
      defaultSort: 'desc',
      render: (row) => (
        <span>{currency.formatMoney(row.sales)}</span>
      ),
    },
  ])

  useEffect(() => {
    setList(data)
  }, [data])

  return (
    <div>
      <MaterialTable
        style={{ marginTop: 40 }}
        title="Faturamento B2C por cidade"
        columns={columns}
        data={list}
        options={{
          actionsColumnIndex: -1,
          sorting: true,
          filtering: true,
        }}
        localization={{
          toolbar: {
            searchTooltip: 'Pesquisar',
            searchPlaceholder: 'Pesquisar',
          },
          body: {
            emptyDataSourceMessage: 'Sem resultados para mostrar',
          },
          pagination: {
            labelRowsSelect: 'linhas',
            labelDisplayedRows: '{from}-{to} de {count}',
          },
        }}
      />
      <p>* No faturamento por cidade cada pedido conta apenas como uma lavagem.</p>
      <p>* A partir de 06/05/2021 foi feita a correção de pegar a cidade e não o bairro.</p>
    </div>
  )
}

export default TableCities
