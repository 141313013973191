import React, { useState, useEffect } from 'react'

import {
  Container, Grid, Link as LinkUI,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import MaterialTable, { Column } from '@material-table/core'

import Skeleton from '@material-ui/lab/Skeleton'

import {
  Link,
} from 'react-router-dom'

// Api
import { collections } from '../../services/api/firebase'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(8, 0, 8),
  },
}))

interface Row {
  id: string;
  user: {
    id: string;
    name: string;
  };
  date: Date;
  city: string;
  state: string;
  address: string;
}

const OrderAttempts: React.FC = () => {
  const classes = useStyles()

  // States

  const columns: Column<Row>[] = [
    {
      title: 'Usuário',
      field: 'user.name',
      render: (row) => (
        <Link to={`/user/${row.user.id}`} component={LinkUI}>{row.user.name}</Link>
      ),
    },
    { title: 'Cidade', field: 'city' },
    { title: 'Estado', field: 'state' },
    { title: 'Endereço', field: 'address' },
    {
      title: 'Data',
      field: 'date',
      filtering: false,
      defaultSort: 'desc',
      render: (row) => {
        // Usar depois moment js!
        const option = {
          year: 'numeric',
          month: ('short' || 'numeric'),
          weekday: ('long' || 'short'),
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }

        const date = row.date.toLocaleDateString('pt-br', option as any)

        return (<div>{date}</div>)
      },
    },
  ]

  const [list, setList] = useState<Row[]>([])
  const [loadingList, setLoadingList] = useState(true)

  // Effects

  useEffect(() => {
    const unsubscribe = collections
      .orderAttempts
      .onSnapshot((snapshot) => {
        const items = snapshot.docs.map((doc) => {
          const data = doc.data()

          return {
            id: data.id,
            user: {
              id: data.user.id,
              name: data.user.name,
            },
            date: data.createdDate.toDate(),
            city: data.city,
            state: data.state,
            address: data.address,
          }
        })

        setList(items)
        setLoadingList(false)
      })

    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Grid container justify="center">
        <Grid item xs={12} lg={8}>
          {loadingList ? (
            <Skeleton variant="rect" width="100%" height={500} />
          ) : (
            <MaterialTable
              title="Tentativas de criação fora da zona de atendimento"
              columns={columns}
              data={list}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                sorting: true,
              }}
              localization={{
                header: {
                  actions: '',
                },
                toolbar: {
                  searchTooltip: 'Pesquisar',
                  searchPlaceholder: 'Pesquisar',
                  exportTitle: 'Exportar',
                  exportAriaLabel: 'Exportar',
                },
                body: {
                  emptyDataSourceMessage: 'Sem resultados para mostrar',
                },
                pagination: {
                  labelRowsSelect: 'linhas',
                  labelDisplayedRows: '{from}-{to} de {count}',
                },
              }}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default OrderAttempts
