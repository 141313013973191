import React, { useEffect, useState } from 'react'
import {
  Container, Paper, Grid, Typography, Button, Theme,
} from '@material-ui/core'

import Skeleton from '@material-ui/lab/Skeleton'

import {
  createStyles, makeStyles,
} from '@material-ui/core/styles'

import {
  useParams,
} from 'react-router-dom'

import { collections } from '../../../services/api/firebase'

import { ChangeOrderAddressProvider } from '../../../contexts/ChangeOrderAddress'

import ChangeAddress from './Modals/ChangeAddress'

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginTop: 40,
    marginBottom: 40,
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: 10,
  },
}))

interface IOrder {
  id: string;
  shortId: string;
  place: {
    city: string;
    complement?: string;
    country: string;
    formattedAddress: string;
    hasNoComplement: boolean;
    hasNoNumber: boolean;
    id: string;
    neighborhood: string;
    postalCode: string;
    state: string;
    streetName: string;
    streetNumber: string;
    type?: string;
  };
}

const ViewOrder: React.FC = () => {
  // Hooks
  const classes = useStyles()
  const { uid } = useParams<any>()

  const [showSkeleton, setShowSkeleton] = useState(true)
  const [addressModal, setAddressModal] = useState(false)

  const [order, setOrder] = useState({} as IOrder)

  const container = (children: JSX.Element): JSX.Element => (
    <Container maxWidth="xl" className={classes.root}>
      <Grid container justify="center">
        <Grid item xs={12} sm={10} lg={8}>
          {children}
        </Grid>
      </Grid>
    </Container>
  )

  useEffect(() => {
    if (!uid) { return }

    collections.orders.doc(uid).onSnapshot((snapshot) => {
      const orderDoc = snapshot.data()

      if (!orderDoc) {
        return
      }

      setOrder({
        shortId: orderDoc.shortId,
        id: orderDoc.id,
        place: {
          city: orderDoc.place.city,
          complement: orderDoc.place.complement,
          country: orderDoc.place.country,
          formattedAddress: orderDoc.place.formattedAddress,
          hasNoComplement: orderDoc.place.hasNoComplement,
          hasNoNumber: orderDoc.place.hasNoNumber,
          id: orderDoc.place.id,
          neighborhood: orderDoc.place.neighborhood,
          postalCode: orderDoc.place.postalCode,
          state: orderDoc.place.state,
          streetName: orderDoc.place.streetName,
          streetNumber: orderDoc.place.streetNumber,
          type: orderDoc.place.type,
        },
      })

      setShowSkeleton(false)
    })
  }, [uid])

  if (showSkeleton) {
    return (container(<Skeleton variant="rect" width="100%" height={500} />))
  }

  return (
    container(
      <>
        <Typography variant="h5">
          Pedido ID
          {' '}
          {order.shortId}
        </Typography>
        <Paper className={classes.paper}>
          <Typography variant="h6" style={{ paddingBottom: 10 }}>Endereço</Typography>
          <Grid
            container
            justify="space-between"
            spacing={2}
            style={{ marginBottom: 10 }}
          >
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2">Estado</Typography>
              <Typography variant="body2">{order.place.state}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2">Cidade</Typography>
              <Typography variant="body2">{order.place.city}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2">Bairro</Typography>
              <Typography variant="body2">{order.place.neighborhood}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2">CEP</Typography>
              <Typography variant="body2">{order.place.postalCode}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2">Rua</Typography>
              <Typography variant="body2">{order.place.streetName}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2">Número</Typography>
              <Typography variant="body2">{order.place.streetNumber}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2">Complemento</Typography>
              <Typography variant="body2">{order.place.complement ?? 'N/A'}</Typography>
            </Grid>
          </Grid>

          <ChangeOrderAddressProvider>
            <ChangeAddress
              open={addressModal}
              onClose={() => setAddressModal(false)}
              orderId={uid}
            />
          </ChangeOrderAddressProvider>

          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={() => setAddressModal(true)}
          >
            Editar endereço
          </Button>

        </Paper>
      </>,
    )
  )
}

export default ViewOrder
