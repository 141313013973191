import React, {
  createContext, useState, useContext,
  useCallback,
} from 'react'

import { IAddressPlace, IAddressContext, IAddressScreens } from './types'

// Contexts
const AddressContext = createContext({} as IAddressContext)

export const AddressProvider: React.FC = (props) => {
  const [place, setPlace] = useState({} as IAddressPlace)
  const [screen, setScreen] = useState<IAddressScreens>('select')
  const [isLoading, setIsLoading] = useState(false)
  const [onSubmit, setOnSubmit] = useState<(place: IAddressPlace) => void>(() => {})
  const [handleClose, setHandleClose] = useState<() => void>(() => {})

  const selectPlace = useCallback((_place: IAddressPlace) => {
    setPlace(_place)
    setScreen('confirm')
  }, [])

  const confirmPlace = useCallback((_place: IAddressPlace) => {
    const formatAddress = (data: IAddressPlace): string => {
      if (!data.streetNumber) {
        return `${data.streetName} - ${data.neighborhood}, ${data.city} - ${data.state}, ${data.postalCode}`
      }

      return `${data.streetName}, ${data.streetNumber} - ${data.neighborhood}, ${data.city} - ${data.state}, ${data.postalCode}`
    }

    onSubmit({
      ..._place,
      formattedAddress: formatAddress(_place),
    })

    handleClose()
  }, [onSubmit, handleClose])

  const { children } = props
  return (
    <AddressContext.Provider
      value={{
        place,
        setPlace,
        screen,
        selectPlace,
        isLoading,
        setIsLoading,
        setOnSubmit,
        confirmPlace,
        setHandleClose,
      }}
    >
      {children}
    </AddressContext.Provider>
  )
}

export const useAddress = (): IAddressContext => {
  const context = useContext(AddressContext)
  return context
}
