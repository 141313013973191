/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'

import MaterialTable, { Column } from '@material-table/core'

import Skeleton from '@material-ui/lab/Skeleton'

// Api
import rating from '../../../services/api/rating'

interface Row {
  createdBy: string;
  reference: string;
  orderId: string;
  customerScore: number;
  dirtScore: number;
  placeScore: number;
  comment?: string;
  createdDate: Date;
}

const CustomerRatings: React.FC = () => {
  const [columns] = useState<Column<Row>[]>([
    {
      title: 'ID do Pedido',
      field: 'orderId',
    },
    {
      title: 'Parceiro',
      field: 'createdBy',
    },
    {
      title: 'Cliente',
      field: 'reference',
    },
    {
      type: 'numeric',
      title: 'Nota Cliente',
      field: 'customerScore',
      lookup: {
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
      },
    },
    {
      type: 'numeric',
      title: 'Nota Sujidade',
      field: 'dirtScore',
      lookup: {
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
      },
    },
    {
      type: 'numeric',
      title: 'Nota Local',
      field: 'placeScore',
      lookup: {
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
      },
    },
    {
      title: 'Data da Criação',
      field: 'createdDate',
      type: 'datetime',
      filtering: false,
      defaultSort: 'desc',
      render: (row) => {
        // Usar depois moment js!
        const option = {
          year: 'numeric',
          month: ('short' || 'numeric'),
          weekday: ('long' || 'short'),
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }

        const date = new Date(row.createdDate).toLocaleDateString('pt-br', option as any)

        return (<div>{date}</div>)
      },
    },
  ])

  const [list, setList] = useState<Row[]>([])
  const [loadingList, setLoadingList] = useState(true)

  // Effects

  useEffect(() => {
    const unsubscribe = rating.getAll('customer', (ratings) => {
      const items = ratings.map((item) => ({
        createdBy: item.partner.name,
        reference: item.customer.name,
        orderId: item.order.shortId,
        customerScore: item.scores.find((value) => value.type === 'customer')?.value as number,
        dirtScore: item.scores.find((value) => value.type === 'dirt')?.value as number,
        placeScore: item.scores.find((value) => value.type === 'place')?.value as number,
        createdDate: item.createdDate,
        comment: item.comment ?? '',
      } as Row))

      setList(items)

      setLoadingList(false)
    })

    return () => {
      unsubscribe()
    }
  }, [])

  if (loadingList) {
    return (
      <Skeleton variant="rect" width="100%" height={500} />
    )
  }

  return (

    <MaterialTable
      title="Avaliações"
      columns={columns}
      data={list}
      options={{
        actionsColumnIndex: -1,
        filtering: true,
        sorting: true,
      }}
      localization={{
        header: {
          actions: '',
        },
        toolbar: {
          searchTooltip: 'Pesquisar',
          searchPlaceholder: 'Pesquisar',
          exportTitle: 'Exportar',
          exportAriaLabel: 'Exportar',
        },
        body: {
          emptyDataSourceMessage: 'Sem resultados para mostrar',
        },
        pagination: {
          labelRowsSelect: 'linhas',
          labelDisplayedRows: '{from}-{to} de {count}',
        },
      }}
      detailPanel={[
        {
          tooltip: 'Mostrar Comentário',
          render: (rowData) => {
            if (!rowData.comment) return null

            return (
              <div style={{ padding: 10 }}>
                <p><strong>Comentário:</strong></p>
                <p>{rowData.comment}</p>
              </div>
            )
          },
        },
      ]}
    />

  )
}

export default CustomerRatings
