import React, { useState, useEffect } from 'react'

import {
  Container, Grid,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import MaterialTable, { Column } from '@material-table/core'

import Skeleton from '@material-ui/lab/Skeleton'

import {
  useHistory,
} from 'react-router-dom'

// Api
import { User } from '../../services/api'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(8, 0, 8),
  },
}))

interface Row {
  uid: string;
  name: string;
  phoneNumber: string;
  state: string;
  status: string;
  date: number;
  city: string;
  score: string;
}

const PartnerWaitingList: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()

  // States

  const columns: Column<Row>[] = [
    { title: 'Nome', field: 'name', filtering: false },
    { title: 'Score', field: 'score' },
    { title: 'Telefone', field: 'phoneNumber', filtering: false },
    { title: 'Cidade', field: 'city' },
    { title: 'Estado', field: 'state' },
    {
      title: 'Status',
      field: 'status',
      lookup: {
        approved: 'Aprovado',
        'under-review': 'Em análise',
        'on-reserve': 'Na reserva',
        waiting: 'Em espera',
        canceled: 'Cancelado',
        refused: 'Recusado',
      },
    },
    {
      title: 'Última atualização',
      field: 'date',
      type: 'date',
      filtering: false,
      defaultSort: 'desc',
      render: (rowDate) => {
        // Usar depois moment js!
        const option = {
          year: 'numeric',
          month: ('short' || 'numeric'),
          weekday: ('long' || 'short'),
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }

        const partnerReviewDate = new Date(
          rowDate.date,
        ).toLocaleDateString('pt-br', option as any)

        return (<div>{partnerReviewDate}</div>)
      },
    },
  ]

  const [list, setList] = useState<Row[]>([])
  const [loadingList, setLoadingList] = useState(true)

  // Effects

  useEffect(() => {
    const unsubscribe = User.getPartnersSnapshot((snapshot) => {
      const items = snapshot.docs.map((doc) => {
        const userDoc = {
          ...doc.data(),
          uid: doc.id,
        } as any

        const item: Row = {
          uid: userDoc.uid,
          name: userDoc.displayName,
          phoneNumber: userDoc.phoneNumber,
          state: userDoc.address?.state ?? 'N/A',
          date: Number(userDoc.partner?.lastUpdate?.toMillis()),
          status: String(userDoc.partner?.status),
          city: userDoc.address?.city ?? 'N/A',
          score: userDoc.rating?.partner ?? 'N/A',
        }

        return item
      })

      setList(items)
      setLoadingList(false)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Grid container justify="center">
        <Grid item xs={12} lg={8}>
          {loadingList ? (
            <Skeleton variant="rect" width="100%" height={500} />
          ) : (
            <MaterialTable
              title="Lista de Parceiros"
              columns={columns}
              data={list}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                sorting: true,
              }}
              localization={{
                header: {
                  actions: '',
                },
                toolbar: {
                  searchTooltip: 'Pesquisar',
                  searchPlaceholder: 'Pesquisar',
                  exportTitle: 'Exportar',
                  exportAriaLabel: 'Exportar',
                },
                body: {
                  emptyDataSourceMessage: 'Sem resultados para mostrar',
                },
                pagination: {
                  labelRowsSelect: 'linhas',
                  labelDisplayedRows: '{from}-{to} de {count}',
                },
              }}
              actions={[
                {
                  icon: 'visibility',
                  tooltip: 'Visualizar',
                  onClick: (event, rowData: any) => {
                    history.push(`/user/${rowData.uid}`)
                  },
                },
              ]}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default PartnerWaitingList
