import { collections } from '../firebase'
import { IGetAllRatingsResponse, IRating } from './types'

const rating = {
  getAll: (category: string, callback: IGetAllRatingsResponse) => {
    const unsubscribe = collections.ratings
      .where('category', '==', category)
      .onSnapshot((snapshot) => {
        const items = snapshot.docs.map((doc) => {
          const ratingDoc = doc.data()

          return {
            customer: {
              name: ratingDoc.customer.name,
              id: ratingDoc.customer.id,
            },
            partner: {
              name: ratingDoc.partner.name,
              id: ratingDoc.partner.id,
            },
            order: {
              id: ratingDoc.order.id,
              shortId: ratingDoc.order.shortId,
            },
            scores: ratingDoc.scores,
            arrivedLate: ratingDoc.lated,
            comment: ratingDoc.comment,
            createdDate: ratingDoc.createdDate.toDate(),
          } as IRating
        })

        callback(items)
      })

    return unsubscribe
  },
}

export default rating
