import React, { useState, useEffect } from 'react'

import {
  Container, Grid, InputAdornment, TextField, Typography, Link as LinkUI,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import MaterialTable, { Column } from '@material-table/core'

import Skeleton from '@material-ui/lab/Skeleton'

import moment from 'moment'

import {
  Link,
} from 'react-router-dom'

// Api
import { User } from '../../../services/api'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(8, 0, 8),
  },
}))

interface Row {
  uid: string;
  name: string;
  phoneNumber: string;
  createdAt: Date;
  email: string;
}

const PartnerWaitingList: React.FC = () => {
  const classes = useStyles()

  const [columns] = useState<Column<Row>[]>([
    {
      title: 'Nome',
      field: 'name',
      render: (row) => (
        <Link to={`/user/${row.uid}`} component={LinkUI} style={{ textTransform: 'capitalize' }}>{row.name}</Link>
      ),
    },
    { title: 'Telefone', field: 'phoneNumber' },
    { title: 'E-mail', field: 'email' },
    {
      title: 'Criado em',
      field: 'createdAt',
      type: 'datetime',
      defaultSort: 'desc',
      render: (row) => (
        <div>{String(row.createdAt)}</div>
      ),
      filterComponent: ({ columnDef, onFilterChanged }: any) => (
        <>
          <TextField
            id="CreatedFrom"
            type="date"
            onChange={(event) => {
              const { value } = event.target

              onFilterChanged(columnDef.tableData.id, {
                dateTo: columnDef.tableData.filterValue?.dateTo,
                dateFrom: value || undefined,
              })
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography>De</Typography>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            id="CreatedTo"
            type="date"
            onChange={(event) => {
              const { value } = event.target

              onFilterChanged(columnDef.tableData.id, {
                dateFrom: columnDef.tableData.filterValue?.dateFrom,
                dateTo: value || undefined,
              })
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography>Até</Typography>
                </InputAdornment>
              ),
            }}
          />
          <button
            style={{ display: 'block', marginTop: 10 }}
            type="button"
            onClick={() => {
              onFilterChanged(columnDef.tableData.id, {
                dateFrom: undefined,
                dateTo: undefined,
              })

              const inputFrom = document.getElementById('CreatedFrom') as any
              const inputTo = document.getElementById('CreatedTo') as any

              inputFrom.value = ''
              inputTo.value = ''
            }}
          >
            Limpar
          </button>
        </>
      ),
      customFilterAndSearch: (filter, rowData) => {
        if (!filter.dateFrom || !filter.dateTo) return true

        const dateFrom = moment(filter.dateFrom)
          .set('hours', 0)
          .set('minutes', 0)
          .set('seconds', 0)

        const dateTo = moment(filter.dateTo)
          .set('hours', 23)
          .set('minutes', 59)
          .set('seconds', 59)

        return moment(rowData.createdAt).isBetween(dateFrom, dateTo)
      },
    },
  ])

  const [list, setList] = useState<Row[]>([])
  const [loadingList, setLoadingList] = useState(true)

  // Effects

  useEffect(() => {
    const unsubscribe = User.getAllUsersSnapshot((snapshot) => {
      const items = snapshot.docs.map((doc) => {
        const userDoc = {
          ...doc.data(),
          uid: doc.id,
        } as any

        const item: Row = {
          uid: userDoc.uid,
          name: userDoc.displayName,
          phoneNumber: userDoc.phoneNumber,
          email: userDoc.email.toLowerCase(),
          createdAt: userDoc.createdAt.toDate(),
        }

        return item
      })

      setList(items)
      setLoadingList(false)
    })

    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Grid container justify="center">
        <Grid item xs={12} sm={10} lg={6}>
          {loadingList ? (
            <Skeleton variant="rect" width="100%" height={500} />
          ) : (
            <MaterialTable
              title="Lista de Usuários"
              columns={columns}
              data={list}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                sorting: true,
                search: false,
              }}
              localization={{
                header: {
                  actions: '',
                },
                toolbar: {
                  searchTooltip: 'Pesquisar',
                  searchPlaceholder: 'Pesquisar',
                  exportTitle: 'Exportar',
                  exportAriaLabel: 'Exportar',
                },
                body: {
                  emptyDataSourceMessage: 'Sem resultados para mostrar',
                },
                pagination: {
                  labelRowsSelect: 'linhas',
                  labelDisplayedRows: '{from}-{to} de {count}',
                },
              }}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default PartnerWaitingList
