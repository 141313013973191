/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react'
import {
  useParams,
} from 'react-router-dom'
import Wash from '../../../services/api/wash'

const OrderReceipt: React.FC = () => {
  const { uid } = useParams<any>()
  const [innerHTML, setInnerHTML] = useState('')

  useEffect(() => {
    (async () => {
      const html = await Wash.getOrderReceipt({
        orderId: uid,
      })

      console.log(html)

      setInnerHTML(html)
    })()
  }, [uid])

  if (innerHTML === '') return null

  return (
    <div dangerouslySetInnerHTML={{ __html: innerHTML }} />
  )
}

export default OrderReceipt
