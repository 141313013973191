import React, { useEffect } from 'react'
import {
  Paper, Modal, makeStyles, Theme, createStyles,
} from '@material-ui/core'

import { useChangeOrderAddress } from '../../../../../contexts/ChangeOrderAddress'

import SelectAddress from '../SelectAddress'
import ConfirmAddress from '../ConfirmAddress'

import { LoadingBackdrop } from '../../../../../components'

interface IProps {
  open: boolean;
  onClose: () => void;
  orderId: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  modal: {
    position: 'absolute',
    width: '90%',
    padding: theme.spacing(2),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.up('sm')]: {
      width: 500,
    },
  },
}))

const ChangeAddress: React.FC<IProps> = (props: IProps) => {
  const classes = useStyles()

  const { open, onClose, orderId } = props
  const {
    setOrderId, isLoading, screen, setScreen, setCloseModal,
  } = useChangeOrderAddress()

  useEffect(() => {
    setOrderId(orderId)
    setCloseModal(() => onClose)
  }, [setOrderId, orderId, setCloseModal, onClose])

  const getScreen = (): JSX.Element => {
    switch (screen) {
      case 'confirm': {
        return <ConfirmAddress />
      }
      default:
      case 'select': {
        return <SelectAddress />
      }
    }
  }

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setScreen('select')
          onClose()
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <>
          <LoadingBackdrop open={isLoading} />
          <Paper className={classes.modal}>
            {getScreen()}
          </Paper>
        </>
      </Modal>
    </>
  )
}

export default ChangeAddress
