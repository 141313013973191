import React from 'react'

import {
  makeStyles,
} from '@material-ui/core/styles'

import {
  Backdrop,
} from '@material-ui/core'

import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

interface Props {
  open: boolean;
}

const LoadingBackdrop: React.FC<Props> = (props: Props) => {
  const { open } = props

  // Hooks
  const classes = useStyles()

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

export default LoadingBackdrop
