/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'

import {
  Container, Grid, TextField, Typography, InputAdornment, Link as LinkUI,
} from '@material-ui/core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  faCreditCard, faBarcode, faKey,
} from '@fortawesome/free-solid-svg-icons'

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

import BusinessIcon from '@material-ui/icons/Business'

import { makeStyles } from '@material-ui/core/styles'

import MaterialTable, { Column } from '@material-table/core'
import { ExportCsv, ExportPdf } from '@material-table/exporters'

import Skeleton from '@material-ui/lab/Skeleton'

import {
  Link,
} from 'react-router-dom'

import moment from 'moment'

// Api
import Wash, { WashingDoc } from '../../../services/api/wash'

import { ReactComponent as TicketLogSVG } from '../../../assets/svgs/ticket-log.svg'
import { ReactComponent as PicpaySVG } from '../../../assets/svgs/picpay.svg'
import { ReactComponent as PixSVG } from '../../../assets/svgs/pix.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(8, 0, 8),
  },
}))

interface Row {
  uid: string;
  orderId: number;
  washType: string;
  date: Date;
  createdAt: Date;
  location: string;
  partner: {
    displayName?: string;
    id: string | null;
    paid: boolean;
  };
  user: {
    displayName: string;
    id: string;
  };
  status: {
    id: string;
    name: string;
    color: string;
  };
  company: {
    name: string;
  } | null;
  invoice: string;
  plates: string[];
  voucher?: string;
  paymentMethod: string;
  repurchase?: boolean;
  additionalServices: {
    id: string;
    name: string;
  }[];
}

const PartnerWaitingList: React.FC = () => {
  const classes = useStyles()

  // <div href={`/user/${user.id}`}>{user.displayName}</div>

  const [list, setList] = useState<Row[]>([])
  const [loadingList, setLoadingList] = useState(true)

  const [columns] = useState<Column<Row>[]>([
    {
      title: 'ID',
      field: 'orderId',
      editable: 'never',
      render: ({ uid, orderId }) => (
        <Link to={`/order/${uid}`} component={LinkUI}>{orderId}</Link>
      ),
    },
    {
      title: 'Cliente',
      field: 'user.displayName',
      editable: 'never',
      render: ({ user }) => (
        <>
          {user.id !== 'N/A' ? (
            <Link to={`/user/${user.id}`} component={LinkUI}>{user.displayName}</Link>
          ) : (
            <span>{user.displayName}</span>
          )}
        </>
      ),
    },
    {
      title: 'Parceiro',
      field: 'partner.displayName',
      render: ({ partner }) => (
        <Link to={`/user/${partner.id}`} component={LinkUI}>{partner.displayName}</Link>
      ),
    },
    {
      title: 'Recompra?',
      field: 'repurchase',
      editable: 'never',
      lookup: {
        true: 'Sim',
        false: 'Não',
        undefined: 'Indefinido',
      },
      align: 'center',
    },
    {
      title: 'Parceiro pago?',
      field: 'partner.paid',
      editable: 'never',
      align: 'center',
      lookup: {
        true: 'Sim',
        false: 'Não',
        undefined: 'Indefinido',
      },
    },
    { title: 'Local', field: 'location', editable: 'never' },
    {
      title: 'Carros',
      field: 'plates',
      editable: 'never',
      render: ({ plates }) => {
        if (!plates) return null

        return (
          <ul>
            {plates.map((value) => (
              <ul>
                <li>
                  {value}
                </li>
              </ul>
            ))}
          </ul>
        )
      },
    },
    {
      title: 'B2B',
      field: 'company.name',
      editable: 'never',
    },
    {
      title: 'Cupom utilizado',
      field: 'voucher',
      editable: 'never',
    },
    {
      title: 'Tipo da lavagem',
      field: 'washType',
      editable: 'never',
      lookup: {
        'external-simple-wash': 'Lavagem externa',
        'internal-and-external-simple-wash': 'Lavagem interna e externa',
      },
    },
    {
      editable: 'never',
      lookup: {
        AIR_SANITATION: 'Higienização do Ar-condicionado',
        WINDSHIELD_CRYSTALIZATION: 'Cristalização de Parabrisas',
      },
      field: 'additionalServices',
      title: 'Serviços adicionais',
      render: (rowData) => (
        <ul>
          {rowData.additionalServices.map((value) => (
            <ul key={value.id}>
              <li>
                {value.name}
              </li>
            </ul>
          ))}
        </ul>

      ),
      customFilterAndSearch: (filter, rowData) => {
        if (!filter) { return true }
        const containsAll = filter.every((i: any) => rowData.additionalServices.map((values) => values.id).includes(i))
        return containsAll
      },
    },
    {
      title: 'Meio de pagamento',
      field: 'paymentMethod',
      editable: 'never',
      align: 'center',
      lookup: {
        'credit-card': 'Cartão de Crédito',
        ticket: 'Ticket Log',
        boleto: 'Boleto Bancário',
        picpay: 'PicPay',
        pix: 'Pix',
        'company-code': 'B2B',
      },
      render: (row) => {
        switch (row.paymentMethod) {
          case 'credit-card': {
            return <FontAwesomeIcon icon={faCreditCard} size="lg" />
          }
          case 'ticket': {
            return <TicketLogSVG height={50} width={50} />
          }
          case 'picpay': {
            return <PicpaySVG height={50} width={50} />
          }
          case 'pix': {
            return <PixSVG height={50} width={50} />
          }
          case 'boleto': {
            return <FontAwesomeIcon icon={faBarcode} size="lg" />
          }
          case 'company-code': {
            return <BusinessIcon />
          }
          default: {
            // Olds docs
            if (row.company) {
              return <BusinessIcon />
            }

            return <FontAwesomeIcon icon={faKey} size="lg" />
          }
        }
      },
    },
    {
      title: 'Status',
      field: 'status.id',
      lookup: {
        'waiting-for-payment': 'Aguardando pagamento',
        'waiting-for-partner-to-accept': 'Aguardando confirmação',
        'waiting-for-partner': 'Agendamento confirmado',
        'partner-on-the-way': 'A caminho',
        'partner-arrived': 'No local',
        completed: 'Finalizado',
        canceled: 'Cancelado',
      },
      align: 'center',
      render: (data) => (
        <Grid container alignItems="center" justify="center">
          <FiberManualRecordIcon style={{ color: data.status.color, marginRight: 5 }} />
          {data.status.name}
        </Grid>
      ),
    },
    {
      title: 'Criado em',
      field: 'createdAt',
      editable: 'never',
      type: 'datetime',
      defaultSort: 'desc',
      render: (row) => {
      // Usar depois moment js!
        const option = {
          year: 'numeric',
          month: ('short' || 'numeric'),
          weekday: ('long' || 'short'),
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }

        const createdAtDate = new Date(row.createdAt).toLocaleDateString('pt-br', option as any)

        return (<div>{createdAtDate}</div>)
      },
      filterComponent: ({ columnDef, onFilterChanged }: any) => (
        <>
          <TextField
            type="date"
            onChange={(event) => {
              const { value } = event.target

              onFilterChanged(columnDef.tableData.id, {
                dateTo: columnDef.tableData.filterValue?.dateTo,
                dateFrom: value || undefined,
              })
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography>De</Typography>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            type="date"
            onChange={(event) => {
              const { value } = event.target

              onFilterChanged(columnDef.tableData.id, {
                dateFrom: columnDef.tableData.filterValue?.dateFrom,
                dateTo: value || undefined,
              })
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography>Até</Typography>
                </InputAdornment>
              ),
            }}
          />
        </>
      ),
      customFilterAndSearch: (filter, rowData) => {
        if (!filter.dateFrom || !filter.dateTo) return true

        const dateFrom = moment(filter.dateFrom)
          .set('hours', 0)
          .set('minutes', 0)
          .set('seconds', 0)

        const dateTo = moment(filter.dateTo)
          .set('hours', 23)
          .set('minutes', 59)
          .set('seconds', 59)

        return moment(rowData.createdAt).isBetween(dateFrom, dateTo)
      },
    },
    {
      title: 'Data do agendamento',
      field: 'date',
      type: 'datetime',
      filterComponent: ({ columnDef, onFilterChanged }: any) => (
        <>
          <TextField
            type="date"
            onChange={(event) => {
              const { value } = event.target

              onFilterChanged(columnDef.tableData.id, {
                dateFrom: value || undefined,
                dateTo: columnDef.tableData.filterValue?.dateTo,
              })
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography>De</Typography>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            type="date"
            onChange={(event) => {
              const { value } = event.target

              onFilterChanged(columnDef.tableData.id, {
                dateFrom: columnDef.tableData.filterValue?.dateFrom,
                dateTo: value || undefined,
              })
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography>Até</Typography>
                </InputAdornment>
              ),
            }}
          />
        </>
      ),
      customFilterAndSearch: (filter, rowData) => {
        if (!filter.dateFrom || !filter.dateTo) return true

        const dateFrom = moment(filter.dateFrom)
          .set('hours', 0)
          .set('minutes', 0)
          .set('seconds', 0)

        const dateTo = moment(filter.dateTo)
          .set('hours', 23)
          .set('minutes', 59)
          .set('seconds', 59)

        return moment(rowData.date).isBetween(dateFrom, dateTo)
      },
      render: (rowDate) => {
        // Usar depois moment js!
        const option = {
          year: 'numeric',
          month: ('short' || 'numeric'),
          weekday: ('long' || 'short'),
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }

        const schedulingDate = new Date(rowDate.date).toLocaleDateString('pt-br', option as any)

        return (<div>{schedulingDate}</div>)
      },
    },
  ])

  // Effects
  useEffect(() => {
    const unsubscribe = Wash.getWashingOrders((snapshot) => {
      const items = snapshot.docs.map((doc) => {
        const orderDoc = {
          ...doc.data(),
          uid: doc.id,
        } as WashingDoc

        const getInvoice = (): string => {
          if (!orderDoc.invoice) {
            return 'Indefinido'
          }

          if (orderDoc.invoice.waiting) {
            return 'Aguardando'
          }

          if (orderDoc.invoice.id) {
            return 'Sim'
          }

          return 'Não'
        }

        const getPaymentMethod = (): string => {
          if (orderDoc.company) {
            return 'company-code'
          }

          const { method } = orderDoc.paymentDetails
          const gateway = orderDoc.paymentDetails.gateway ?? 'juno'

          switch (method) {
            case 'credit-card': {
              switch (gateway) {
                case 'ticket-log': {
                  return 'ticket'
                }
                default: {
                  return method
                }
              }
            }
            default: {
              return method
            }
          }
        }

        const item: Row = {
          uid: orderDoc.uid ?? '',
          washType: orderDoc.type.id,
          date: orderDoc.date.toDate(),
          status: {
            id: orderDoc.status,
            name: Wash.getStatusName(orderDoc.status),
            color: Wash.getStatusColor(orderDoc.status),
          },
          location: orderDoc.formattedLocation,
          partner: {
            id: orderDoc.partner.id ?? null,
            displayName: orderDoc.partner.displayName,
            paid: orderDoc.partner.paid,
          },
          user: {
            id: orderDoc.user.id,
            displayName: orderDoc.user.displayName,
          },
          createdAt: orderDoc.createdAt.toDate(),
          company: orderDoc.company ? {
            name: `${orderDoc.company.name} (${orderDoc.company.code})`,
          } : null,
          voucher: orderDoc.voucher?.id,
          paymentMethod: getPaymentMethod(),
          repurchase: orderDoc.user.repurchase,
          orderId: orderDoc.createdAt.seconds,
          plates: orderDoc.cars?.map((value) => `${value.model} ${value.color} ${value.plate}`),
          invoice: getInvoice(),
          additionalServices: orderDoc.additionalServices ?? [],
        }

        return item

        /* if (change.type === 'added') {
          setList((o) => {
            // Se o user já estiver presente no lista, não fazer nada.
            if (o.find((order) => order.uid === item.uid)) {
              return [...o]
            }

            return [...o, item]
          })
        } else if (change.type === 'removed') {
          setList((o) => o.filter((order) => order.uid !== item.uid))
        } else if (change.type === 'modified') {
          setList((o) => o.map((order) => (order.uid === item.uid ? item : order)))
        } */
      })

      setList(items)

      setLoadingList(false)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <Container maxWidth="xl" className={classes.root}>

      <Grid container justify="center">
        <Grid item xs={12}>
          {loadingList ? (
            <Skeleton variant="rect" width="100%" height={500} />
          ) : (
            <>
              <MaterialTable
                title="Lista de Pedidos"
                columns={columns}
                data={list}
                options={{
                  search: false,
                  actionsColumnIndex: -1,
                  filtering: true,
                  sorting: true,
                  exportAllData: true,
                  columnsButton: true,
                  pageSize: 10,
                  pageSizeOptions: [10, 50, 100, 200, 500],
                  exportMenu: [{
                    label: 'Export PDF',
                    exportFunc: (cols, datas) => ExportPdf(cols, datas, 'carbee-pedidos'),
                  }, {
                    label: 'Export CSV',
                    exportFunc: (cols, datas) => ExportCsv(cols, datas, 'carbee-pedidos'),
                  }],
                }}
                localization={{
                  header: {
                    actions: '',
                  },
                  toolbar: {
                    searchTooltip: 'Pesquisar',
                    searchPlaceholder: 'Pesquisar',
                    exportTitle: 'Exportar',
                    exportAriaLabel: 'Exportar',
                  },
                  body: {
                    emptyDataSourceMessage: 'Sem resultados para mostrar',
                  },
                  pagination: {
                    labelRowsSelect: 'linhas',
                    labelDisplayedRows: '{from}-{to} de {count}',
                  },
                }}
                editable={{
                  onRowUpdate: async (newData, oldData) => {
                    const convertDate = (date: string | Date): string => {
                      if (typeof date === 'string') {
                        return date
                      }
                      return date.toISOString()
                    }

                    let partnerId: string | undefined

                    /**
                     * O partnerId vai vim pelo displayName
                     */

                    // Verificar se houve mudança no parceiro
                    if (oldData && (newData.partner.displayName !== oldData.partner.displayName)) {
                      partnerId = newData.partner.displayName
                    } else if (oldData) {
                      partnerId = oldData.partner.id ?? undefined
                    }

                    const { status, uid, date } = newData

                    try {
                      switch (status.id) {
                        case 'completed': {
                          await Wash.completeOrder(uid)
                          break
                        }
                        case 'canceled': {
                          await Wash.cancelOrder(uid)
                          break
                        }
                        default: {
                          await Wash.updateOrder({
                            date: convertDate(date),
                            status: status.id,
                            orderId: uid,
                            partnerId,
                          })
                        }
                      }
                    } catch (err) {
                      let message: string

                      switch (err.code) {
                        case 'invalid-argument': {
                          message = err.details
                          break
                        }
                        default: {
                          message = 'Ocorreu algum problema, entre em contato com a equipe de T.I!'
                        }
                      }

                      alert(message)
                    }
                  },
                }}
              />
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default PartnerWaitingList
