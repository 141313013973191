import React, { useState, useEffect } from 'react'
import {
  Grid, Typography, Link as LinkUI,
} from '@material-ui/core'

import MaterialTable, { Column } from '@material-table/core'

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

import moment from 'moment'

import {
  Link,
} from 'react-router-dom'

import Wash, { WashingDoc } from '../../../../services/api/wash'
import Constants from '../../../../utils/constants'

interface Row {
  uid: string;
  orderId: number;
  client: {
    name: string;
    id: string;
  };
  partner: {
    name: string;
    id: string;
  };
  place: string;
  cars: string[];
  status: {
    id: string;
    name: string;
    color: string;
  };
  schedulingDate: Date;
  situation: string;
}

interface IGetOrderSituationData {
  schedulingDate: Date;
  status: string;
}

const getOrderSituation = (data: IGetOrderSituationData): string => {
  const { schedulingDate, status } = data

  const nowMoment = moment()

  const schedulintDateMoment = moment(schedulingDate)

  if (
    status === 'waiting-for-partner'
    && moment(nowMoment).isAfter(schedulintDateMoment.subtract(Constants.MISSING_HOW_LONG_THE_PARTNER_NEEDS_TO_GO, 'minutes'))
  ) {
    return 'atrasado'
  }

  if (
    (status === 'waiting-for-partner' || status === 'partner-on-the-way' || status === 'partner-arrived')
    && moment(nowMoment).isAfter(schedulintDateMoment.add(Constants.TIME_TO_FINALIZE_THE_ORDER - 30, 'minutes'))
  ) {
    return 'atrasado'
  }

  if (
    (status === 'waiting-for-partner' || status === 'partner-on-the-way' || status === 'partner-arrived')
    && moment(nowMoment).isAfter(schedulintDateMoment.add(Constants.TIME_TO_FINALIZE_THE_ORDER, 'minutes'))
  ) {
    return 'não pode mais ser finalizado'
  }

  if (
    (status === 'waiting-for-partner-to-accept')
    && moment(nowMoment).isAfter(schedulintDateMoment.subtract(Constants.TIME_TO_ORDER_EXPIRES, 'minutes'))
  ) {
    return 'não pode mais ser aceito'
  }

  return 'ok'
}

const OrdersForToday: React.FC = () => {
  const [list, setList] = useState<Row[]>([])

  const [columns] = useState<Column<Row>[]>([
    {
      title: 'Identificação',
      render: (data) => (
        <div>
          Pedido
          {' '}
          {data.orderId}
          {' '}
          -
          {' '}
          {data.client.name}
        </div>
      ),
    },
    {
      title: 'Parceiro',
      field: 'partner.displayName',
      render: (row) => (
        <Link to={`/user/${row.partner.id}`} component={LinkUI}>{row.partner.name}</Link>
      ),
    },
    { title: 'Local', field: 'place' },
    {
      title: 'Carros',
      field: 'cars',
      render: (row) => (
        <ul>
          {row.cars.map((value) => (
            <ul key={value}>
              <li>
                {value}
              </li>
            </ul>
          ))}
        </ul>
      ),
    },
    {
      title: 'Status',
      field: 'status.id',
      lookup: {
        'waiting-for-payment': 'Aguardando pagamento',
        'waiting-for-partner-to-accept': 'Aguardando confirmação',
        'waiting-for-partner': 'Agendamento confirmado',
        'partner-on-the-way': 'A caminho',
        'partner-arrived': 'No local',
        completed: 'Finalizado',
        canceled: 'Cancelado',
      },

      render: (data) => (
        <Grid container alignItems="center">
          <FiberManualRecordIcon style={{ color: data.status.color, marginRight: 5 }} />
          {data.status.name}
        </Grid>
      ),

    },
    {
      filtering: false,
      title: 'Data do agendamento',
      field: 'schedulingDate',
      type: 'datetime',
      render: (row) => {
        // Usar depois moment js!
        const option = {
          year: 'numeric',
          month: ('short' || 'numeric'),
          weekday: ('long' || 'short'),
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }

        const schedulingDate = new Date(row.schedulingDate).toLocaleDateString('pt-br', option as any)

        return (<div>{schedulingDate}</div>)
      },

    },
    {
      title: 'Situação',
      field: 'situation',
      lookup: {
        ok: 'ok',
        atrasado: 'atrasado',
        'não pode mais ser aceito': 'não pode mais ser aceito',
        'não pode mais ser finalizado': 'não pode mais ser finalizado',
      },
      render: (row) => {
        let color = ''
        switch (row.situation) {
          case 'atrasado': {
            color = 'orange'
            break
          }
          case 'não pode mais ser aceito': {
            color = 'red'
            break
          }
          case 'não pode mais ser finalizado': {
            color = 'red'
            break
          }
          default: {
            color = 'black'
          }
        }

        return (
          <div style={{ color, fontWeight: 'bold', textTransform: 'uppercase' }}>{row.situation}</div>
        )
      },
    },
  ])

  useEffect(() => {
    const dateFrom = moment().set({ hour: 0, minute: 0, seconds: 0 }).toDate()
    const dateTo = moment().set({ hour: 23, minute: 59, seconds: 59 }).toDate()

    const unsubscribe = Wash.collection()
      .where('date', '>', dateFrom)
      .where('date', '<', dateTo)
      .onSnapshot((snapshot) => {
        const items = snapshot.docs.map((doc) => {
          const orderDoc = {
            ...doc.data(),
            uid: doc.id,
          } as WashingDoc

          const item: Row = {
            uid: orderDoc.uid ?? '',
            orderId: orderDoc.createdAt.seconds,
            client: {
              name: orderDoc.user.displayName,
              id: orderDoc.user.id,
            },
            partner: {
              id: orderDoc.partner.id ?? '',
              name: orderDoc.partner.displayName ?? '',
            },
            status: {
              id: orderDoc.status,
              name: Wash.getStatusName(orderDoc.status),
              color: Wash.getStatusColor(orderDoc.status),
            },
            schedulingDate: orderDoc.date.toDate(),
            cars: orderDoc.cars?.map((value) => `${value.model} ${value.color} ${value.plate}`),
            place: orderDoc.formattedLocation,
            situation: getOrderSituation({
              schedulingDate: orderDoc.date.toDate(),
              status: orderDoc.status,
            }),
          }

          return item
        })

        setList(items)
      })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    setInterval(() => {
      if (document.hidden) {
        return
      }

      setList((l) => {
        if (!l.length) return []

        return (
          l.map((row) => ({
            ...row,
            situation: getOrderSituation({
              schedulingDate: row.schedulingDate,
              status: row.status.id,
            }),
          }))
        )
      })
    }, 1000 * 5)
  }, [])

  return (
    <Grid item xs={12} style={{ marginBottom: 50 }}>
      <MaterialTable
        title={(
          <Grid container alignItems="center">
            <Typography variant="h6">Pedidos agendados para hoje</Typography>
            <Typography style={{ paddingLeft: 10 }}>
              <Link to="/orders">
                ver todos os pedidos
              </Link>
            </Typography>
          </Grid>
        )}
        columns={columns}
        data={list}
        options={{
          actionsColumnIndex: -1,
          sorting: true,
          filtering: true,
        }}
        localization={{
          toolbar: {
            searchTooltip: 'Pesquisar',
            searchPlaceholder: 'Pesquisar',
          },
          body: {
            emptyDataSourceMessage: 'Sem resultados para mostrar',
          },
          pagination: {
            labelRowsSelect: 'linhas',
            labelDisplayedRows: '{from}-{to} de {count}',
          },
        }}
      />
    </Grid>
  )
}

export default OrdersForToday
