import { formatMoney, settings } from 'accounting'

settings.currency = {
  symbol: 'R$', // default currency symbol is '$'
  format: '%s %v', // controls output: %s = symbol, %v = value/number (can be object: see below)
  decimal: ',', // decimal point separator
  thousand: '.', // thousands separator
  precision: 2, // decimal places
}

const currency = {
  formatMoney: (number: number, symbol: 'R$' = 'R$'): string => {
    const ret = formatMoney(number, symbol)
    return ret
  },
}

export default currency
