import {
  firebase, db, functions,
} from './firebase'

import { PaymentMethods } from './user'

export interface WashingType {
  id: string;
  price: number;
}

export interface CarType {
  model: string;
  plate: string;
  color: string;
}

// eslint-disable-next-line max-len
export type WashingStatus = 'waiting-for-payment' | 'waiting-for-partner-to-accept' | 'waiting-for-partner' | 'partner-on-the-way' | 'partner-arrived' | 'completed' | 'canceled'

export interface WashingDoc {
  user: {
    id: string;
    displayName: string;
    phoneNumber: string;
    repurchase?: boolean;
  };
  partner: {
    id: string | null;
    displayName?: string;
    phoneNumber?: string;
    assignmentDate?: firebase.firestore.Timestamp;
    amount?: number;
    paid: boolean;
    invoice?: string;
  };
  // car: CarType;
  cars: CarType[];
  createdAt: firebase.firestore.Timestamp;
  date: firebase.firestore.Timestamp;
  coordinates: firebase.firestore.GeoPoint;
  formattedLocation: string;
  paymentDetails: {
    cardId: string | null;
    cardLastFourDigits: string | null;
    method: PaymentMethods;
    amount: number;
    amountWithoutDiscount: number;
    transactionId: number | null;
    paymentId: string | null;
    chargeId: string;
    link: string | null;
    gateway: string;
  };
  type: WashingType;
  status: WashingStatus;
  // Custom fields (não estão presentes no banco de dados)
  formattedCreatedAt?: string;
  formattedDate?: string;
  formattedHour?: string;
  orderId?: string;
  uid?: string;
  formattedAssignmentDate: string;
  voucher?: {
    id: string;
    discount: number;
  };
  complement?: string;
  company: {
    name: string;
    code: string;
  } | null;
  invoice?: {
    id: null | string;
    waiting: boolean;
  };
  place: any;
  additionalServices: {
    price: number;
    id: string;
    name: string;
  }[];
}

type SnapshotCallback = (
  snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
) => void

type UpdateOrderData = {
  orderId: string;
  date: string;
  status: string;
  partnerId?: string;
}

class Wash {
  static getWashingOrders(callback: SnapshotCallback): any {
    const unsubscribe = db.collection('washingOrders')
      .onSnapshot((snapshot) => {
        callback(snapshot)
      })

    return unsubscribe
  }

  static async updateOrder(data: UpdateOrderData): Promise<void> {
    console.log(data)
    const cloudUpdateOrderFromPanel = functions.httpsCallable('updateOrderFromPanel')
    await cloudUpdateOrderFromPanel(data)
  }

  static async completeOrder(orderId: string): Promise<void> {
    const cloudCompleteOrder = functions.httpsCallable('completeOrder')
    await cloudCompleteOrder({ orderId })
  }

  static async cancelOrder(orderId: string): Promise<void> {
    const cloudCancelOrder = functions.httpsCallable('cancelOrder')
    await cloudCancelOrder({ orderId })
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  static collection() {
    return db.collection('washingOrders')
  }

  static getTypeName(id: string): string {
    let washTypeName

    switch (id) {
      case 'external-simple-wash': {
        washTypeName = 'Lavagem Externa'

        break
      }
      case 'internal-and-external-simple-wash': {
        washTypeName = 'Lavagem Interna & Externa'

        break
      }
      default: {
        washTypeName = 'N/A'
      }
    }

    return washTypeName
  }

  static getStatusColor(id: WashingStatus): string {
    let color

    switch (id) {
      case 'waiting-for-partner-to-accept': {
        color = 'orange'
        break
      }
      case 'waiting-for-payment': {
        color = 'grey'
        break
      }
      case 'waiting-for-partner':
      case 'partner-on-the-way':
      case 'partner-arrived': {
        color = 'green'
        break
      }
      case 'completed': {
        color = 'grey'
        break
      }
      case 'canceled': {
        color = 'red'
        break
      }
      default: {
        color = 'red'
      }
    }

    return color
  }

  static getStatusName(id: WashingStatus): string {
    let washStatusName

    switch (id) {
      case 'waiting-for-partner-to-accept': {
        washStatusName = 'Aguardando confirmação'
        break
      }
      case 'waiting-for-payment': {
        washStatusName = 'Aguardando pagamento'
        break
      }
      case 'waiting-for-partner': {
        washStatusName = 'Agendamento confirmado'
        break
      }
      case 'partner-on-the-way': {
        washStatusName = 'Bee a caminho'
        break
      }
      case 'partner-arrived': {
        washStatusName = 'Bee chegou ao local'
        break
      }
      case 'completed': {
        washStatusName = 'Finalizado'
        break
      }
      case 'canceled': {
        washStatusName = 'Cancelado'
        break
      }
      default: {
        washStatusName = 'N/A'
      }
    }

    return washStatusName
  }

  static async parseAddress(
    adressComponents: any,
  ): Promise<any> {
    const fn = functions.httpsCallable('parseAddress')
    const ret = await fn(adressComponents)
    return ret.data
  }

  static async parseGeo(
    latitude: number, longitude: number,
  ): Promise<any> {
    const fn = functions.httpsCallable('parseGeo')
    const ret = await fn({ latitude, longitude })
    return ret.data
  }

  static async updateOrderAddress(
    orderId: string,
    place: any,
  ): Promise<void> {
    console.log('place', place)
    const fn = functions.httpsCallable('updateOrderAddress')
    await fn({
      orderId,
      place,
      fromPanel: true,
    })
  }

  static async getOrderTypes(): Promise<any[]> {
    const fn = functions.httpsCallable('getWashTypes')
    const ret = await fn()
    return ret.data
  }

  static async createOrder(data: object): Promise<string> {
    const fn = functions.httpsCallable('createOrderFromPanel')
    const response = await fn(data)
    return response.data
  }

  static async getOrderReceipt(data: object): Promise<string> {
    const fn = functions.httpsCallable('getOrderReceipt')
    const response = await fn(data)
    return response.data
  }
}

export default Wash
