import React from 'react'

import {
  Dialog, DialogActions, DialogContent, DialogTitle, Button,
} from '@mui/material'

import {
  Field, Formik, Form,
} from 'formik'

import * as Yup from 'yup'

import { TextField } from 'formik-mui'

import { IAddBeeDialogProps } from './types'
import { collections } from '../../../../services/api/firebase'

const AddBeeDialog: React.FC<IAddBeeDialogProps> = (props: IAddBeeDialogProps) => {
  const { onSubmit, open, handleClose } = props

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="Adicionar Bee"
      fullWidth
      maxWidth="xs"
    >
      <Formik
        initialValues={{
          id: '',
        }}
        onSubmit={async (values) => {
          const userSnap = await collections.users.doc(values.id).get()

          const userData = userSnap.data()

          if (!userData) {
            alert('Usuário não encontrado')
            return
          }

          onSubmit({
            id: values.id,
            name: userData.displayName,
          })
          handleClose()
        }}
        validationSchema={Yup.object({
          id: Yup.string().required('É necessário informar o id do bee.'),
        })}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
            <DialogTitle id="form-dialog-title">Adicionar Bee</DialogTitle>
            <DialogContent>

              <Field
                fullWidth
                component={TextField}
                name="id"
                type="text"
                label="ID do bee"
                variant="standard"
              />

            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancelar
              </Button>
              <Button
                disabled={isSubmitting}
                type="submit"
                color="primary"
              >
                Adicicionar
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default AddBeeDialog
