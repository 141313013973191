import React from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'

const Loading: React.FC = () => (
  <div style={{
    display: 'flex',
    position: 'absolute',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  }}
  >
    <CircularProgress />
  </div>
)

export default Loading
