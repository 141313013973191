/**
 * Apenas um teste, todos em minutos!
 */

enum Constants {
  TIME_TO_ORDER_EXPIRES = 30,
  TIME_TO_FINALIZE_THE_ORDER = 180,
  MISSING_HOW_LONG_THE_PARTNER_NEEDS_TO_GO = 60
}

export default Constants
