import React from 'react'

import {
  Typography, Button,
} from '@material-ui/core'

import LockOutlinedIcon from '@material-ui/icons/LockOutlined'

// Api
import { Auth } from '../../services/api'

const WithoutPermission: React.FC = () => (
  <div style={{
    display: 'flex',
    position: 'absolute',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'red',
    color: 'white',
    flexDirection: 'column',
  }}
  >
    <div>
      <LockOutlinedIcon fontSize="large" />
    </div>

    <Typography variant="h6">Sem permissão de acesso.</Typography>
    <Button
      variant="outlined"
      style={{ marginTop: 15 }}
      onClick={() => Auth.signOut()}
    >
      Sair
    </Button>
  </div>
)

export default WithoutPermission
