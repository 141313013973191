import { Typography } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import GooglePlacesAutocomplete, { geocodeByAddress } from 'react-google-places-autocomplete'
import { useChangeOrderAddress } from '../../../../../contexts/ChangeOrderAddress'
import Wash from '../../../../../services/api/wash'

const SelectAddress: React.FC = () => {
  const { selectPlace, setIsLoading } = useChangeOrderAddress()
  const [value, setValue] = useState<any>(null)

  useEffect(() => {
    (async () => {
      if (value) {
        try {
          setIsLoading(true)

          const [result] = await geocodeByAddress(value.label)

          const response = await Wash.parseAddress(result.address_components)

          if (!response.zipCode || !response.streetName || !response.streetNumber) {
            const responseByGeo = await Wash.parseGeo(result.geometry.location.lat(), result.geometry.location.lng())

            console.log(responseByGeo)

            selectPlace({
              latitude: result.geometry.location.lat(),
              longitude: result.geometry.location.lng(),
              state: responseByGeo.stateShort,
              city: responseByGeo.city,
              country: responseByGeo.countryShort,
              streetName: responseByGeo.streetName,
              streetNumber: responseByGeo.streetNumber,
              postalCode: responseByGeo.zipCode,
              formattedAddress: responseByGeo.formattedAddress,
              neighborhood: responseByGeo.neighborhood,
              complement: undefined,
              hasNoComplement: false,
              hasNoNumber: false,
            })

            return
          }

          selectPlace({
            latitude: result.geometry.location.lat(),
            longitude: result.geometry.location.lng(),
            state: response.stateShort,
            city: response.city,
            country: response.countryShort,
            streetName: response.streetName,
            streetNumber: response.streetNumber,
            postalCode: response.zipCode,
            formattedAddress: result.formatted_address,
            neighborhood: response.neighborhood,
            complement: undefined,
            hasNoComplement: false,
            hasNoNumber: false,
          })
        } catch (err) {
          alert('Ocorreu um erro desconhecido, por favor contate a equipe de T.I!')
        } finally {
          setIsLoading(false)
        }
      }
    })()
  }, [value, selectPlace, setIsLoading])

  return (
    <div>
      <Typography variant="h6" style={{ marginBottom: 10 }}>Selecione um endereço</Typography>

      <GooglePlacesAutocomplete
        apiKey="AIzaSyBhOGOzM4TqezljxWPyJcklVNuWfL-Sc0Q"
        selectProps={{
          value,
          onChange: setValue,
        }}
        withSessionToken
        apiOptions={{ language: 'pt', region: 'BR' }}

      />
    </div>
  )
}

export default SelectAddress
