/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react'
import {
  Container, Paper, Grid, Typography, Divider, Button, Box, Chip, Theme, TextField, MenuItem,
  InputLabel,
  FormControl,
  Select,
} from '@material-ui/core'

import Skeleton from '@material-ui/lab/Skeleton'

import {
  createStyles, makeStyles, useTheme,
} from '@material-ui/core/styles'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import {
  red, green, pink, purple, orange,
} from '@material-ui/core/colors'

import {
  useParams,
} from 'react-router-dom'

import { useFormik } from 'formik'
import * as Yup from 'yup'

// Api
import { User } from '../../../services/api'

// Components
import { LoadingBackdrop } from '../../../components'

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginTop: 40,
    marginBottom: 40,
  },
  page__section: {
    paddingBottom: '10px',
    paddingTop: '10px',
  },
  page__divider: {
    marginTop: '20px',
  },
  page__button__decline: {
    backgroundColor: red[400],
    color: 'white',
    '&:hover': {
      backgroundColor: red[900],
    },
  },
  page__button__accept: {
    backgroundColor: green[400],
    color: 'white',
    '&:hover': {
      backgroundColor: green[900],
    },
  },
  page__button__reserve: {
    backgroundColor: orange[400],
    color: 'white',
    marginRight: 10,
    '&:hover': {
      backgroundColor: orange[900],
    },
  },
  page__paper: {
    padding: '20px 20px 20px 20px',
  },
  page__chip: {
    marginRight: theme.spacing(1),
  },
  page__chip__admin: {
    backgroundColor: pink[400],
    color: 'white',
  },
  page__chip__partner: {
    backgroundColor: purple[400],
    color: 'white',
  },
}))

const App: React.FC = () => {
  // Hooks
  const classes = useStyles()
  const theme = useTheme()
  const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const { uid } = useParams<any>()

  // States
  const [userData, setUserData] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [verificationImages, setVerificationImages] = useState<any>()
  const [partnerLevel, setPartnerLevel] = useState('')

  const formikBankAccount = useFormik({
    initialValues: {
      agencyNumber: userData?.bankAccount?.agencyNumber ?? '',
      accountNumber: userData?.bankAccount?.accountNumber ?? '',
      bankNumber: userData?.bankAccount?.bankNumber ?? '',
      type: userData?.bankAccount?.accountType ?? '',
      complementNumber: userData?.bankAccount?.accountComplementNumber ?? '',
      holderName: '',
      holderDocument: '',
    },
    validationSchema: Yup.object({
      agencyNumber: Yup.string()
        .required('É necessário informar a agência.'),
      accountNumber: Yup.string()
        .required('É necessário informar o número da conta.'),
      bankNumber: Yup.string()
        .required('É necessário informar o número do banco.'),
      type: Yup.string()
        .required('É necessário informar a operação.'),
      holderName: Yup.string()
        .required('É necessário informar o nome do titular.'),
      holderDocument: Yup.string()
        .required('É necessário informar o documento do titular.'),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        setIsLoading(true)

        await User.updateBankAccount({
          userId: uid,
          accountNumber: values.accountNumber,
          agencyNumber: values.agencyNumber,
          complementNumber: values.complementNumber,
          type: values.type,
          bankNumber: values.bankNumber,
          holderName: values.holderName,
          holderDocument: values.holderDocument,
        })

        alert('Conta bancária atualizada com sucesso!')

        setIsLoading(false)
        resetForm({ values })
      } catch (err) {
        console.log(err)

        console.log(err.code)

        let message = ''

        switch (err.code) {
          case 'invalid-argument': {
            message = err.details
            break
          }
          default: {
            message = 'Ocorreu algum erro, tente novamente.'
          }
        }

        alert(message)

        setIsLoading(false)
      }
    },
  })

  const formikUser = useFormik({
    initialValues: {
      name: '',
      email: '',
      phoneNumber: '',
      permissionLevel: 0,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('É necessário informar um nome.'),
      email: Yup.string()
        .required('É necessário informar um email.'),
      phoneNumber: Yup.string()
        .required('É necessário informar um número de telefone.'),
      permissionLevel: Yup.number()
        .required('É necessário informar um nível de permissão'),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        setIsLoading(true)

        await User.updateUser({
          id: uid,
          phoneNumber: values.phoneNumber,
          email: values.email,
          name: values.name,
          permissionLevel: values.permissionLevel,
        })

        alert('Dados do usuário atualizados com sucesso!')

        setIsLoading(false)
        resetForm({ values })
      } catch (err) {
        console.log(err)

        console.log(err.code)

        let message = ''

        switch (err.code) {
          case 'permission-denied':
          case 'invalid-argument': {
            message = err.details
            break
          }
          default: {
            message = 'Ocorreu algum erro, tente novamente.'
          }
        }

        alert(message)

        setIsLoading(false)
      }
    },
  })

  const { setFieldValue } = formikBankAccount

  const formikBusinessAccount = useFormik({
    initialValues: {
      tag: userData?.business?.tag ?? '',
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        setIsLoading(true)

        await User.setBusinessAccount({
          userId: uid,
          tag: values.tag,
        })

        setIsLoading(false)
        resetForm({ values })
      } catch (err) {
        let message = ''

        switch (err.code) {
          default: {
            message = 'Ocorreu algum erro, tente novamente.'
          }
        }

        alert(message)

        setIsLoading(false)
      }
    },
  })

  const setBusinessFieldValue = formikBusinessAccount.setFieldValue

  const setUserFieldValue = formikUser.setFieldValue

  // Functions
  const handlePartnerAction = async (): Promise<void> => {
    try {
      setIsLoading(true)
      await User.updatePartner(uid, partnerLevel as any)
    } catch (err) {
      // Erros do gateway
      if (err.code === 'aborted') {
        alert(`Erro referente ao gateway: ${err.message}`)
      } else {
        alert('Erro desconhecido, necessita de inspeção nos logs do servidor.')
      }
    }

    setIsLoading(false)
  }

  // Effects

  useEffect(() => {
    const unsubscribe = User.getUserSnapshot(uid, (snapshot) => {
      const userDoc = {
        ...snapshot.data(),
        uid: snapshot.id,
      } as any

      setUserData({
        ...userDoc,
        admin: userDoc.admin || 0,
      })

      setPartnerLevel(userDoc.partner?.status)
    })

    return () => unsubscribe()
  }, [uid])

  useEffect(() => {
    (async () => {
      const ret = await User.getVerificationImages(uid)
      setVerificationImages(ret)
    })()
  }, [uid])

  useEffect(() => {
    if (!userData) return

    setFieldValue('agencyNumber', userData.bankAccount?.agencyNumber)
    setFieldValue('accountNumber', userData.bankAccount?.accountNumber)
    setFieldValue('bankNumber', userData.bankAccount?.bankNumber)
    setFieldValue('type', userData.bankAccount?.accountType)
    setFieldValue('complementNumber', userData.bankAccount?.accountComplementNumber)

    setBusinessFieldValue('tag', userData.business?.tag)

    setUserFieldValue('name', userData.displayName)
    setUserFieldValue('email', userData.email)
    setUserFieldValue('phoneNumber', userData.phoneNumber)
    setUserFieldValue('permissionLevel', userData.admin ?? 0)
  }, [userData, setFieldValue, setBusinessFieldValue, setUserFieldValue])

  return (
    <Container maxWidth="xl" className={classes.root}>
      <LoadingBackdrop open={isLoading} />
      <Grid container justify="center">
        <Grid item xs={12} sm={10} lg={8}>
          {userData == null ? (
            <>
              <Skeleton variant="rect" width="100%" height={80} />
              <Box mt={3}>
                <Skeleton variant="rect" width="100%" height={300} />
              </Box>
            </>
          ) : (
            <>
              <Grid
                container
                justify={matchesUpMd ? undefined : 'center'}
              >
                <Box component="div" mb={1}>
                  {userData.partner?.status === 'approved' && (
                    <Chip className={`${classes.page__chip} ${classes.page__chip__partner}`} label="Parceiro" />
                  )}
                </Box>
                <Box component="div" mb={1}>
                  {userData.partner?.status === 'on-reserve' && (
                    <Chip className={`${classes.page__chip} ${classes.page__chip__partner}`} label="Parceiro reserva" />
                  )}
                </Box>
              </Grid>

              <Box component="div" mb={2}>

                <Grid
                  container
                  alignItems="center"
                  alignContent="center"
                  justify={matchesUpMd ? 'space-between' : 'center'}
                  direction={matchesUpMd ? 'row' : 'column'}
                >
                  <Grid item>
                    <Box component="div" mb={!matchesUpMd ? 1 : 0}>
                      <Typography variant="h5" align="center">
                        ID
                        {' '}
                        {userData.uid}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item>
                        <FormControl style={{ width: '100%', minWidth: 200 }}>
                          <InputLabel id="partnerLevelLabel" shrink>Nível Parceiro</InputLabel>
                          <Select
                            labelId="partnerLevelLabel"
                            value={partnerLevel}
                            onChange={(value) => {
                              setPartnerLevel(value.target.value as string)
                            }}
                            displayEmpty
                          >
                            <MenuItem value="" disabled>
                              Selecione
                            </MenuItem>
                            <MenuItem value="on-reserve">Reserva</MenuItem>
                            <MenuItem value="under-review">Em análise</MenuItem>
                            <MenuItem value="waiting">Em espera</MenuItem>
                            <MenuItem value="approved">Aprovado</MenuItem>
                            <MenuItem value="refused">Recusado</MenuItem>
                            <MenuItem value="canceled">Cancelado</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <Button type="button" variant="contained" className={classes.page__button__accept} onClick={() => handlePartnerAction()}>
                          Salvar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              {userData.partner?.status === 'under-review' && (
                <Grid container justify={matchesUpMd ? 'space-between' : 'center'}>
                  {matchesUpMd && (
                  <div style={{ display: 'flex', flexGrow: 1 }} />
                  )}
                  <Box component="span" mb={1}>
                    <Typography align="center" variant="body2">
                      Pedido para se tornar parceiro realizado em
                      {' '}
                      {userData.partner?.lastUpdate.toDate().toString()}
                    </Typography>
                  </Box>
                </Grid>
              )}

              <Paper className={classes.page__paper}>
                <Grid container justify="space-between">
                  <div style={{ display: 'flex', flexGrow: 1 }} />
                  <Typography variant="body2">
                    Conta criada em
                    {' '}
                    {String(userData.createdAt.toDate())}
                  </Typography>
                </Grid>
                <Typography variant="h6" className={classes.page__section}>Identidade</Typography>

                <form noValidate autoComplete="off" onSubmit={formikUser.handleSubmit}>
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        required
                        fullWidth
                        id="standard-basic"
                        label="Nome"
                        name="name"
                        error={!!(formikUser.touched.name && formikUser.errors.name)}
                        helperText={
                              (formikUser.touched.name && formikUser.errors.name)
                                ? formikUser.errors.name
                                : ''
                            }
                        onChange={formikUser.handleChange}
                        onBlur={formikUser.handleBlur}
                        value={formikUser.values.name}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        required
                        fullWidth
                        id="standard-basic"
                        label="Email"
                        name="email"
                        error={!!(formikUser.touched.email && formikUser.errors.email)}
                        helperText={(formikUser.touched.email && formikUser.errors.email)
                          ? formikUser.errors.email
                          : ''}
                        onChange={formikUser.handleChange}
                        onBlur={formikUser.handleBlur}
                        value={formikUser.values.email}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        required
                        fullWidth
                        id="standard-basic"
                        label="Telefone"
                        name="phoneNumber"
                        error={!!(formikUser.touched.phoneNumber && formikUser.errors.phoneNumber)}
                        helperText={
                              (formikUser.touched.phoneNumber && formikUser.errors.phoneNumber)
                                ? formikUser.errors.phoneNumber
                                : ''
                            }
                        onChange={formikUser.handleChange}
                        onBlur={formikUser.handleBlur}
                        value={formikUser.values.phoneNumber}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body1">CPF:</Typography>
                      <Typography variant="body2">{userData.document?.number}</Typography>
                    </Grid>

                  </Grid>
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl style={{ width: '100%' }}>
                        <InputLabel id="permissionLevelLabel">Nível de Permissão</InputLabel>
                        <Select
                          labelId="permissionLevelLabel"
                          id="selectPermissionLevel"
                          value={formikUser.values.permissionLevel}
                          onChange={(value) => {
                            setUserFieldValue('permissionLevel', value.target.value as number)
                          }}
                        >
                          <MenuItem value={0}>Normal</MenuItem>
                          <MenuItem value={10}>Gerente de Área</MenuItem>
                          <MenuItem value={30}>Suporte</MenuItem>
                          <MenuItem value={50}>Admin</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={!formikUser.isValid || !formikUser.dirty}
                      >
                        Salvar
                      </Button>
                    </Grid>
                  </Grid>

                </form>
                <Divider className={classes.page__divider} />
                <Typography variant="h6" className={classes.page__section}>Endereço</Typography>
                <Grid
                  container
                  justify="space-between"
                  spacing={2}
                >
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">País:</Typography>
                    <Typography variant="body2">Brasil</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">Estado:</Typography>
                    <Typography variant="body2">{userData.address?.state}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">Cidade:</Typography>
                    <Typography variant="body2">{userData.address?.city}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">Rua:</Typography>
                    <Typography variant="body2">{userData.address?.streetName}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">Número:</Typography>
                    <Typography variant="body2">{userData.address?.streetNumber}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">CEP:</Typography>
                    <Typography variant="body2">{userData.address?.postalCode}</Typography>
                  </Grid>
                </Grid>

                <Divider className={classes.page__divider} />

                <Typography variant="h6" className={classes.page__section}>Conta Bancária</Typography>

                <form noValidate autoComplete="off" onSubmit={formikBankAccount.handleSubmit}>

                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        required
                        fullWidth
                        id="standard-basic"
                        label="Agência"
                        name="agencyNumber"
                        error={!!(formikBankAccount.touched.agencyNumber && formikBankAccount.errors.agencyNumber)}
                        helperText={
                          (formikBankAccount.touched.agencyNumber && formikBankAccount.errors.agencyNumber)
                            ? formikBankAccount.errors.agencyNumber
                            : ''
                        }
                        onChange={formikBankAccount.handleChange}
                        onBlur={formikBankAccount.handleBlur}
                        value={formikBankAccount.values.agencyNumber}
                        inputProps={{ maxLength: 16 }}

                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        required
                        fullWidth
                        id="standard-basic"
                        label="Número do banco"
                        name="bankNumber"
                        error={!!(formikBankAccount.touched.bankNumber && formikBankAccount.errors.bankNumber)}
                        helperText={(formikBankAccount.touched.bankNumber && formikBankAccount.errors.bankNumber)
                          ? formikBankAccount.errors.bankNumber
                          : ''}
                        onChange={formikBankAccount.handleChange}
                        onBlur={formikBankAccount.handleBlur}
                        value={formikBankAccount.values.bankNumber}
                        inputProps={{ maxLength: 3 }}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        required
                        fullWidth
                        id="standard-basic"
                        label="Número da conta"
                        name="accountNumber"
                        error={!!(formikBankAccount.touched.accountNumber && formikBankAccount.errors.accountNumber)}
                        helperText={
                          (formikBankAccount.touched.accountNumber && formikBankAccount.errors.accountNumber)
                            ? formikBankAccount.errors.accountNumber
                            : ''
                        }
                        onChange={formikBankAccount.handleChange}
                        onBlur={formikBankAccount.handleBlur}
                        value={formikBankAccount.values.accountNumber}
                        inputProps={{ maxLength: 32 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Código complementar"
                        name="complementNumber"
                        error={!!(formikBankAccount.touched.complementNumber && formikBankAccount.errors.complementNumber)}
                        helperText={(formikBankAccount.touched.complementNumber && formikBankAccount.errors.complementNumber)
                          ? formikBankAccount.errors.complementNumber
                          : 'Apenas para contas caixa'}
                        onChange={formikBankAccount.handleChange}
                        onBlur={formikBankAccount.handleBlur}
                        value={formikBankAccount.values.complementNumber}
                        inputProps={{ maxLength: 3 }}
                      />

                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        required
                        fullWidth
                        id="standard-basic"
                        label="Nome do titular"
                        name="holderName"
                        error={!!(formikBankAccount.touched.holderName && formikBankAccount.errors.holderName)}
                        helperText={
                          (formikBankAccount.touched.holderName && formikBankAccount.errors.holderName)
                            ? formikBankAccount.errors.holderName
                            : ''
                        }
                        onChange={formikBankAccount.handleChange}
                        onBlur={formikBankAccount.handleBlur}
                        value={formikBankAccount.values.holderName}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        required
                        fullWidth
                        id="standard-basic"
                        label="Documento do titular"
                        name="holderDocument"
                        error={!!(formikBankAccount.touched.holderDocument && formikBankAccount.errors.holderDocument)}
                        helperText={(formikBankAccount.touched.holderDocument && formikBankAccount.errors.holderDocument)
                          ? formikBankAccount.errors.holderDocument
                          : ''}
                        onChange={formikBankAccount.handleChange}
                        onBlur={formikBankAccount.handleBlur}
                        value={formikBankAccount.values.holderDocument}
                      />

                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        fullWidth
                        required
                        select
                        id="standard-select-currency"
                        label="Operação"
                        name="type"
                        error={!!(formikBankAccount.touched.type && formikBankAccount.errors.type)}
                        helperText={formikBankAccount.touched.type && formikBankAccount.errors.type ? formikBankAccount.errors.type : ''}
                        onChange={formikBankAccount.handleChange}
                        onBlur={formikBankAccount.handleBlur}
                        value={formikBankAccount.values.type}
                      >
                        <MenuItem value="SAVINGS">Poupança</MenuItem>
                        <MenuItem value="CHECKING">Corrente</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={!formikBankAccount.isValid || !formikBankAccount.dirty}
                      >
                        Salvar
                      </Button>
                    </Grid>
                  </Grid>

                </form>

                {verificationImages && (
                  <>
                    <Divider className={classes.page__divider} />
                    <Typography variant="h6" className={classes.page__section}>Documentação</Typography>
                    <Grid
                      container
                    >
                      <Grid item xs={12}>
                        <a href={verificationImages.documentPicture}>
                          <img src={verificationImages.documentPicture} alt="Foto do documento" style={{ height: '200px', width: '200px' }} />
                        </a>
                      </Grid>
                      <Grid item xs={12}>
                        <a href={verificationImages.selfieDocument}>
                          <img src={verificationImages.selfieDocument} alt="Foto do documento" style={{ height: '200px', width: '200px' }} />
                        </a>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Paper>

              <form noValidate autoComplete="off" onSubmit={formikBusinessAccount.handleSubmit}>
                <Grid
                  container
                  spacing={2}
                  justify="center"
                >
                  <Grid item xs={12} md={4}>
                    <TextField
                      required
                      fullWidth
                      id="standard-basic"
                      label="Tag"
                      name="tag"
                      inputProps={{ maxLength: 32 }}
                      error={!!(formikBusinessAccount.touched.tag && formikBusinessAccount.errors.tag)}
                      helperText={
                        (formikBusinessAccount.touched.tag && formikBusinessAccount.errors.tag)
                          ? formikBusinessAccount.errors.tag
                          : ''
                      }
                      onChange={formikBusinessAccount.handleChange}
                      onBlur={formikBusinessAccount.handleBlur}
                      value={formikBusinessAccount.values.tag}
                    />

                    <Button
                      fullWidth
                      style={{ marginTop: 10 }}
                      variant="outlined"
                      type="submit"
                      disabled={!formikBusinessAccount.dirty}
                    >
                      Salvar
                    </Button>
                  </Grid>

                </Grid>

              </form>

            </>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default App
