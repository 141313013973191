import {
  firebase, db, functions, storage,
} from './firebase'

export type PaymentMethods = 'credit-card' | 'boleto' | 'picpay'

export type PaymentOptionData = {
  uid: string;
  method: PaymentMethods;
  creditCard: {
    cardId: string;
    lastFourDigits: string;
  };
}

export interface Address {
  streetNumber: string;
  streetName: string;
  city: string;
  state: string;
  postalCode: string;
  formattedAddres?: string;
  complement?: string;
}

export type DocumentData = {
  type: 'cpf';
  number: number | string;
}

export interface BankAccount {
  bankNumber: string;
  agencyNumber: string;
  accountNumber: string;
  accountType: string;
  accountComplementNumber: string;
}

export interface SignUpData {
  email: string;
  password: string;
  displayName: string;
  phoneNumber: string;
}

export interface SignUpForPartnerData {
  userId: string;
  address: Address;
  document: DocumentData;
  bankAccount: BankAccount;
  selfie: string;
  birthDate: string;
  documentPicture: string;
}

export type PartnerStatus = 'approved' | 'under-review' | 'canceled' | 'refused'

type SnapshotCallback = (
  snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
) => void

type DocumentSnapshotCallback = (
  snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
) => void

interface IUpdateUserBankAccountRequest {
  userId: string;
  agencyNumber: string;
  accountNumber: string;
  type: string;
  complementNumber: string;
  bankNumber: string;
  holderName: string;
  holderDocument: string;
}

interface ISetBusinessAccount {
  userId: string;
  tag?: string;
}

interface IUpdateUser {
  id: string;
  name?: string;
  email?: string;
  phoneNumber?: string;
  permissionLevel?: number;
}

export default class {
  static getPartnersSnapshot(callback: SnapshotCallback): any {
    const unsubscribe = db.collection('users')
      .where('partner.status', 'in', ['approved', 'under-review', 'canceled', 'refused', 'on-reserve', 'waiting'])
      .onSnapshot((snapshot) => {
        callback(snapshot)
      })

    return unsubscribe
  }

  static getAllUsersSnapshot(callback: SnapshotCallback): any {
    const unsubscribe = db.collection('users')
      .onSnapshot((snapshot) => {
        callback(snapshot)
      })

    return unsubscribe
  }

  static getUserSnapshot(
    userId: string,
    callback: DocumentSnapshotCallback,
  ): any {
    const unsubscribe = db.collection('users').doc(userId).onSnapshot((snapshot) => {
      callback(snapshot)
    })

    return unsubscribe
  }

  static async updatePartner(userId: string, status: PartnerStatus): Promise<void> {
    const cloudUpdatePartner = functions.httpsCallable('updatePartner')
    await cloudUpdatePartner({ userId, status })
  }

  static async updateAdmin(userId: string, status: boolean): Promise<void> {
    const cloudUpdateAdmin = functions.httpsCallable('updateAdmin')
    await cloudUpdateAdmin({ userId, status })
  }

  static async getVerificationImages(userId: string): Promise<any> {
    const refSelfieDocument = storage.ref(`users/${userId}/selfieDocument/picture.jpg`)
    const refDocumentPicture = storage.ref(`users/${userId}/documentPicture/picture.jpg`)

    return {
      selfieDocument: await refSelfieDocument.getDownloadURL(),
      documentPicture: await refDocumentPicture.getDownloadURL(),
    }
  }

  static async updateBankAccount(data: IUpdateUserBankAccountRequest): Promise<void> {
    const fcn = functions.httpsCallable('updateUserBankAccount')
    await fcn(data)
  }

  static async setBusinessAccount(data: ISetBusinessAccount): Promise<void> {
    const fcn = functions.httpsCallable('setBusinessAccount')
    await fcn(data)
  }

  static async updateUser(data: IUpdateUser): Promise<void> {
    const fcn = functions.httpsCallable('updateUser')
    await fcn(data)
  }
}
