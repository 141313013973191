import React, { useState } from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import {
  Menu,
} from '@material-ui/icons'

import {
  Container, AppBar, Toolbar, IconButton,
  Drawer, List, ListItem, ListItemIcon, ListItemText, Typography,
} from '@material-ui/core'

import {
  Link,
} from 'react-router-dom'

// Contexts
import { useAuth } from '../../contexts/auth'
import type { IRoutes } from '../../routes/types'

const useStyles = makeStyles((theme: Theme) => createStyles({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawer: {
    [theme.breakpoints.up('xs')]: {
      minWidth: '220px',
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: '300px',
    },
  },
}))

interface IHeaderProps {
  routes: IRoutes;
}

const Header: React.FC<IHeaderProps> = ({ routes }: IHeaderProps) => {
  // Hooks
  const classes = useStyles()

  const { user } = useAuth()

  // States
  const [drawer, setDrawer] = useState(false)

  if (!user) { return null }

  const items = routes.filter((route) => {
    if (route.permissionLevel > user.admin) {
      return false
    }

    if (!route.menu) {
      return false
    }

    return true
  })

  return (
    <>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={() => setDrawer(true)}>
              <Menu />
            </IconButton>
            <div style={{ flexGrow: 1 }} />
            <Typography style={{ textTransform: 'capitalize' }}>{user.name ?? ''}</Typography>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        anchor="left"
        open={drawer}
        onClose={() => setDrawer(false)}
        classes={{
          paper: classes.drawer,
        }}
      >
        <List className={classes.drawer}>
          {items.map((item) => (
            <ListItem
              component={Link}
              to={item.path}
              button
              key={item.name}
              onClick={() => setDrawer(false)}
            >
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText>{item.name}</ListItemText>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  )
}

export default Header
