import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  boxMoney: {
    height: 200,
    border: '0.5px solid rgb(242 241 241);',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'lightgrey',
  },
  boxSplited: {
    height: 200,
    border: '0.5px solid rgb(242 241 241);',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'lightblue',
  },
  box: {
    height: 200,
    border: '0.5px solid rgb(242 241 241);',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
}))

export default useStyles
