import React, {
  createContext, useState, useContext,
  useCallback,
} from 'react'

type IScreens = 'select' | 'confirm'

interface IPlace {
  latitude: number;
  longitude: number;
  state: string;
  city: string;
  country: string;
  streetName: string;
  streetNumber?: string;
  postalCode: string;
  formattedAddress: string;
  neighborhood: string;
  complement?: string;
  hasNoComplement: boolean;
  hasNoNumber: boolean;
}

interface IChangeOrderAddress {
  orderId: string;
  place: IPlace;
  setOrderId: React.Dispatch<React.SetStateAction<string>>;
  setPlace: React.Dispatch<React.SetStateAction<IPlace>>;
  screen: IScreens;
  setScreen: React.Dispatch<React.SetStateAction<IScreens>>;
  selectPlace: (_place: IPlace) => void;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  closeModal: any;
  setCloseModal: React.Dispatch<any>;
}

// Contexts
const ChangeOrderAddressContext = createContext({} as IChangeOrderAddress)

export const ChangeOrderAddressProvider: React.FC = (props) => {
  const [orderId, setOrderId] = useState('')
  const [place, setPlace] = useState({} as IPlace)
  const [screen, setScreen] = useState<IScreens>('select')
  const [isLoading, setIsLoading] = useState(false)
  const [closeModal, setCloseModal] = useState<any>({})

  const selectPlace = useCallback((_place: IPlace) => {
    setPlace(_place)
    setScreen('confirm')
  }, [])

  const { children } = props
  return (
    <ChangeOrderAddressContext.Provider
      value={{
        place,
        setPlace,
        orderId,
        setOrderId,
        screen,
        setScreen,
        selectPlace,
        isLoading,
        setIsLoading,
        closeModal,
        setCloseModal,
      }}
    >
      {children}
    </ChangeOrderAddressContext.Provider>
  )
}

export const useChangeOrderAddress = (): IChangeOrderAddress => {
  const context = useContext(ChangeOrderAddressContext)
  return context
}
