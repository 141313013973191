import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import {
  TextField,
  Grid,
  Theme,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  Breadcrumbs,
} from '@material-ui/core'
import {
  createStyles, makeStyles,
} from '@material-ui/core/styles'
import { useChangeOrderAddress } from '../../../../../contexts/ChangeOrderAddress'
import Wash from '../../../../../services/api/wash'

const useStyles = makeStyles((theme: Theme) => createStyles({
  cityState: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  textField: {
    marginTop: theme.spacing(1),
  },
  checkBox: {
    marginTop: theme.spacing(1) / 2,
  },
}))

const ConfirmAddress: React.FC = () => {
  const {
    place, orderId, setScreen, setIsLoading, closeModal,
  } = useChangeOrderAddress()

  const [hasNoNumber, setHasNoNumber] = useState(false)
  const [hasNoComplement, setHasNoComplement] = useState(false)

  const classes = useStyles()

  const formikPlace = useFormik({
    initialValues: {
      streetName: '',
      streetNumber: '',
      complement: '',
      neighborhood: '',
    },
    validationSchema: Yup.object({
      streetName: Yup.string()
        .required('É necessário informar uma rua.'),
      streetNumber: Yup.string(),
      complement: Yup.string(),
      neighborhood: Yup.string()
        .required('É necessário informar um bairro.'),
    }),
    onSubmit: async (values, { setFieldError }) => {
      let error = false

      if (!hasNoComplement && !values.complement) {
        setFieldError('complement', 'É necessário informar um complemento.')
        error = true
      }

      if (!hasNoNumber && !values.streetNumber) {
        setFieldError('streetNumber', 'É necessário informar um número.')
        error = true
      }

      if (error) {
        return
      }

      try {
        setIsLoading(true)
        await Wash.updateOrderAddress(orderId, {
          ...place,
          complement: values.complement || undefined,
          streetName: values.streetName,
          streetNumber: values.streetNumber || undefined,
          neighborhood: values.neighborhood,
          hasNoNumber,
          hasNoComplement,
          id: 'N/A',
          type: undefined,
        })

        alert('Endereço atualizado com sucesso!')

        setScreen('select')
        closeModal()
      } catch (err) {
        alert('Erro desconhecido, por favor tente novamente.')
      } finally {
        setIsLoading(false)
      }
    },
  })

  const { setFieldValue } = formikPlace

  useEffect(() => {
    setFieldValue('streetName', place.streetName)
    setFieldValue('streetNumber', place.streetNumber ?? '')
    setFieldValue('complement', place.complement ?? '')
    setFieldValue('neighborhood', place.neighborhood)
  }, [setFieldValue, place])

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: 10 }}>
        <Link
          color="inherit"
          href="/"
          onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.preventDefault()
            setScreen('select')
          }}
        >
          Selecionar endereço
        </Link>
        <Typography color="textPrimary">Confirmar endereço</Typography>
      </Breadcrumbs>
      <form noValidate autoComplete="off" onSubmit={formikPlace.handleSubmit}>
        <TextField
          className={classes.textField}
          required
          fullWidth
          id="standard-basic"
          label="Nome da rua"
          name="streetName"
          error={!!(formikPlace.touched.streetName && formikPlace.errors.streetName)}
          helperText={
            (formikPlace.touched.streetName && formikPlace.errors.streetName)
              ? formikPlace.errors.streetName
              : ''
          }
          onChange={formikPlace.handleChange}
          onBlur={formikPlace.handleBlur}
          value={formikPlace.values.streetName}
        />

        <Grid
          container
          spacing={2}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              disabled={hasNoNumber}
              className={classes.textField}
              fullWidth
              id="standard-basic"
              label="Número"
              name="streetNumber"
              error={!!(formikPlace.touched.streetNumber && formikPlace.errors.streetNumber)}
              helperText={
                (formikPlace.touched.streetNumber && formikPlace.errors.streetNumber)
                  ? formikPlace.errors.streetNumber
                  : ''
              }
              onChange={formikPlace.handleChange}
              onBlur={formikPlace.handleBlur}
              value={formikPlace.values.streetNumber}
            />
            <FormControlLabel
              className={classes.checkBox}
              control={(
                <Checkbox
                  checked={hasNoNumber}
                  onChange={() => {
                    setHasNoNumber(!hasNoNumber)
                    setFieldValue('streetNumber', '')
                  }}
                />
              )}
              label="Sem número"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              disabled={hasNoComplement}
              className={classes.textField}
              fullWidth
              id="standard-basic"
              label="Complemento"
              name="complement"
              error={!!(formikPlace.touched.complement && formikPlace.errors.complement)}
              helperText={(formikPlace.touched.complement && formikPlace.errors.complement)
                ? formikPlace.errors.complement
                : ''}
              onChange={formikPlace.handleChange}
              onBlur={formikPlace.handleBlur}
              value={formikPlace.values.complement}
            />
            <FormControlLabel
              className={classes.checkBox}
              control={(
                <Checkbox
                  name="checkedA"
                  checked={hasNoComplement}
                  onChange={() => {
                    setHasNoComplement(!hasNoComplement)
                    setFieldValue('complement', '')
                  }}
                />
              )}
              label="Sem complemento"
            />
          </Grid>
        </Grid>

        <TextField
          className={classes.textField}
          required
          fullWidth
          id="standard-basic"
          label="Bairro"
          name="neighborhood"
          error={!!(formikPlace.touched.neighborhood && formikPlace.errors.neighborhood)}
          helperText={
            (formikPlace.touched.neighborhood && formikPlace.errors.neighborhood)
              ? formikPlace.errors.neighborhood
              : ''
          }
          onChange={formikPlace.handleChange}
          onBlur={formikPlace.handleBlur}
          value={formikPlace.values.neighborhood}
        />

        <Typography className={classes.cityState}>
          {place.city}
          {' '}
          -
          {' '}
          {place.state}
        </Typography>

        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
        >
          Confirmar
        </Button>
      </form>
    </div>
  )
}

export default ConfirmAddress
