/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'

import {
  Container, Grid, Typography, Box, Link as LinkUI,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import MaterialTable, { Column } from '@material-table/core'

import Skeleton from '@material-ui/lab/Skeleton'

import {
  Link,
} from 'react-router-dom'

// Api
import Voucher from '../../../services/api/voucher'
import { IVoucherDoc } from '../../../services/api/voucher/types'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(8, 0, 8),
  },
}))

interface Row {
  id: string;
  discount: number;
  usedTimes: number;
  usageLimit: number;
  purchaseLimit: number;
  expirationDate: Date;
  createdAt: Date;
  createdBy: string;
}

const AllVouchers: React.FC = () => {
  const classes = useStyles()

  const [columns] = useState<Column<Row>[]>([
    {
      title: 'Código',
      field: 'id',
      editable: 'onAdd',
      render: (row) => (
        <Link to={`/vouchers/${row.id}`} component={LinkUI}>{row.id}</Link>
      ),
    },
    {
      title: 'Criado por',
      field: 'createdBy',
      editable: 'never',
    },
    {
      type: 'numeric',
      title: 'Desconto',
      field: 'discount',
      editable: 'onAdd',
    },
    {
      title: 'Quantos usuários poderão usar esse cupom?',
      field: 'usageLimit',
      type: 'numeric',
      initialEditValue: 1,
    },
    {
      title: 'Quantas vezes o usuário poderá comprar com esse cupom?',
      field: 'purchaseLimit',
      initialEditValue: 1,
      type: 'numeric',
    },
    {
      title: 'Vezes utilizado',
      field: 'usedTimes',
      initialEditValue: '0',
      editable: 'never',
      filtering: false,
      type: 'numeric',
    },
    {
      title: 'Data de expiração',
      field: 'expirationDate',
      type: 'datetime',
      render: (row) => {
      // Usar depois moment js!
        const option = {
          year: 'numeric',
          month: ('short' || 'numeric'),
          weekday: ('long' || 'short'),
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }

        const expirationDate = new Date(row.expirationDate).toLocaleDateString('pt-br', option as any)

        return (<div>{expirationDate}</div>)
      },
    },
    {
      title: 'Data de criação',
      field: 'createdAt',
      type: 'datetime',
      defaultSort: 'desc',
      editable: 'never',
      render: (row) => {
      // Usar depois moment js!
        const option = {
          year: 'numeric',
          month: ('short' || 'numeric'),
          weekday: ('long' || 'short'),
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }

        const createdAt = new Date(row.createdAt).toLocaleDateString('pt-br', option as any)

        return (<div>{createdAt}</div>)
      },
    },
  ])

  const [list, setList] = useState<Row[]>([])
  const [loadingList, setLoadingList] = useState(true)

  // Effects

  useEffect(() => {
    const unsubscribe = Voucher.getAll((snapshot) => {
      const items = snapshot.docs.map((doc) => {
        const voucherDoc = {
          ...doc.data(),
          id: doc.id,
        } as IVoucherDoc

        const item: Row = {
          id: voucherDoc.id,
          discount: voucherDoc.discount,
          usedTimes: voucherDoc.usedTimes,
          usageLimit: voucherDoc.usageLimit,
          expirationDate: voucherDoc.expirationDate.toDate(),
          createdAt: voucherDoc.createdAt.toDate(),
          purchaseLimit: voucherDoc.purchaseLimit ?? 1,
          createdBy: voucherDoc.createdBy.name,
        }

        return item
      })

      setList(items)

      setLoadingList(false)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Grid container justify="center">
        <Grid item xs={12} lg={10}>
          {loadingList ? (
            <Skeleton variant="rect" width="100%" height={500} />
          ) : (
            <MaterialTable
              title={(
                <Box display="flex" alignItems="center">
                  <Typography variant="h5">Lista de cupons</Typography>
                  <Box ml={2}>
                    <Link to="/vouchers/create" component={LinkUI}>
                      Criar cupom
                    </Link>
                  </Box>
                </Box>
              )}
              columns={columns}
              data={list}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                sorting: true,
              }}
              localization={{
                header: {
                  actions: '',
                },
                toolbar: {
                  searchTooltip: 'Pesquisar',
                  searchPlaceholder: 'Pesquisar',
                  exportTitle: 'Exportar',
                  exportAriaLabel: 'Exportar',
                },
                body: {
                  emptyDataSourceMessage: 'Sem resultados para mostrar',
                },
                pagination: {
                  labelRowsSelect: 'linhas',
                  labelDisplayedRows: '{from}-{to} de {count}',
                },
              }}
              editable={{
                onRowDelete: async (oldData) => {
                  try {
                    await Voucher.delete(oldData.id)
                  } catch (err) {
                    let message: string

                    switch (err.code) {
                      case 'aborted': {
                        message = err.details
                        break
                      }
                      default: {
                        message = 'Ocorreu algum problema, entre em contato com a equipe de T.I!'
                      }
                    }

                    alert(message)
                  }
                },
              }}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default AllVouchers
