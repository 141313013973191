/* eslint-disable react/jsx-props-no-spreading */

import React from 'react'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'

import {
  Home as HomeIcon, Assignment, ExitToApp, AccountBalance, Star, People, PeopleOutline, Business, SentimentSatisfiedAlt, Assessment, ShoppingCart,
} from '@material-ui/icons'

// Pages
import {
  SignIn,
  SignOut,
  ViewUser,
  AllUsers,
  PartnerList,
  AllOrders,
  ViewOrder,
  CreateOrder,
  Loading,
  WithoutPermission,
  AllVouchers,
  CompaniesCodes,
  Home,
  Financial,
  Ratings,
  Conversions,
  Reports,
  OrderReceipt,
  OrderAttempts,
  UpsertVoucher,
} from '../pages'

// Components
import Header from '../components/Header'

// Contexts
import { useAuth } from '../contexts/auth'

import { IAuthenticatedRouteProps, IRoutes } from './types'
import { AbandonedCarts } from '../pages/AbandonedCarts'

const routes: IRoutes = [
  /**
   * Menu
   */
  {
    name: 'Início',
    component: <Home />,
    icon: <HomeIcon />,
    path: '/',
    menu: true,
    permissionLevel: 1,
  },
  {
    name: 'Usuários',
    component: <AllUsers />,
    icon: <People />,
    path: '/users',
    menu: true,
    permissionLevel: 30,
  },
  {
    name: 'Parceiros',
    component: <PartnerList />,
    icon: <PeopleOutline />,
    path: '/partners',
    menu: true,
    permissionLevel: 1,
  },
  {
    name: 'Pedidos',
    menu: true,
    component: <AllOrders />,
    icon: <Assignment />,
    path: '/orders',
    permissionLevel: 1,
  },
  {
    name: 'Carrinhos abandonados',
    menu: true,
    component: <AbandonedCarts />,
    icon: <ShoppingCart />,
    path: '/carts',
    permissionLevel: 1,
  },
  {
    name: 'Criar Pedido',
    menu: true,
    component: <CreateOrder />,
    icon: <Assignment />,
    path: '/create-order',
    permissionLevel: 30,
  },
  {
    name: 'Cupons',
    menu: true,
    component: <AllVouchers />,
    icon: <Assignment />,
    path: '/vouchers',
    permissionLevel: 1,
  },
  {
    name: 'Criar cupom',
    menu: true,
    component: <UpsertVoucher />,
    icon: <Assignment />,
    path: '/vouchers/create',
    permissionLevel: 1,
  },
  {
    name: 'Códigos de Empresas',
    menu: true,
    component: <CompaniesCodes />,
    icon: <Business />,
    path: '/companies-codes',
    permissionLevel: 1,
  },
  {
    name: 'Financeiro',
    menu: true,
    component: <Financial />,
    icon: <AccountBalance />,
    path: '/financial',
    permissionLevel: 50,
  },
  {
    name: 'Avaliações',
    menu: true,
    component: <Ratings />,
    icon: <Star />,
    path: '/ratings',
    permissionLevel: 1,
  },
  {
    name: 'Conversões',
    menu: true,
    component: <Conversions />,
    icon: <SentimentSatisfiedAlt />,
    path: '/conversions',
    permissionLevel: 1,
  },
  {
    name: 'Relatórios',
    menu: true,
    component: <Reports />,
    icon: <Assessment />,
    path: '/reports',
    permissionLevel: 1,
  },
  {
    name: 'Tentativas de criação de pedido',
    menu: true,
    component: <OrderAttempts />,
    icon: <Assignment />,
    path: '/orderAttempts',
    permissionLevel: 1,
  },
  {
    name: 'Sair',
    menu: true,
    component: <SignOut />,
    path: '/sign-out',
    permissionLevel: 0,
    icon: <ExitToApp />,
  },
  /**
   * Não estão no menu
   */
  {
    component: <ViewUser />,
    path: '/user/:uid',
    permissionLevel: 1,
  },
  {
    component: <UpsertVoucher />,
    path: '/vouchers/:id',
    permissionLevel: 1,
  },
  {
    component: <ViewOrder />,
    path: '/order/:uid',
    permissionLevel: 1,
  },
  {
    component: <OrderReceipt />,
    path: '/orderReceipt/:uid',
    permissionLevel: 1,
    hideHeader: true,
  },
  {
    component: <div>Not found</div>,
    path: '*',
    permissionLevel: 0,
  },
]

const AuthenticatedRoute: React.FC<IAuthenticatedRouteProps> = ({
  children,
  permissionLevel,
  hideHeader,
  ...rest
}: IAuthenticatedRouteProps) => {
  const { user } = useAuth()

  if (!user) {
    return null
  }

  if (permissionLevel > user.admin) {
    alert('Sem permissão suficiente para acessar este recurso!')

    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    )
  }

  return (
    <Route
      {...rest}
      render={() => (
        <>
          {!hideHeader && (
            <Header routes={routes} />
          )}
          <>
            {children}
          </>
        </>
      )}
    />
  )
}

const Routes: React.FC = () => {
  const { signed, appLoaded, user } = useAuth()

  if (!appLoaded) {
    return <Loading />
  }

  if (user && !user?.admin) {
    return <WithoutPermission />
  }

  return (
    <Router>
      {signed ? (
        <Switch>
          {routes.map((route) => (
            <AuthenticatedRoute
              key={route.path}
              exact
              path={route.path}
              permissionLevel={route.permissionLevel}
              hideHeader={route.hideHeader}
            >
              {route.component}
            </AuthenticatedRoute>
          ))}
        </Switch>
      ) : (
        <Switch>
          <Route path="*">
            <SignIn />
          </Route>
        </Switch>
      )}

    </Router>
  )
}

export default Routes
